<script>
    import {mapGetters} from 'vuex';
    import DatePicker from './date-picker';
    import AdvancedSearch from './advanced-search';
    import NumberFormatter from './number-formatter';
    import Multiselect from 'vue-multiselect';
    import {required} from 'vuelidate/lib/validators';
    import Axios from 'axios';
    import AdvancedSearchForLookup from './advanced-search-for-lookup';

    export default {
        name: 'vehicle-block',
        props: {
            card: {},
            vehiclePaintCodes: {
                type: Array,
                default: () => [],
            },
            propsIsAutoSave: false,
          isFromPlanner: false,
            isNewCard: false,
            isCardEdit: false,
            isEstimateEdit: false,
            propsEstimateMethodology: null,
            propsEstimate: null,
            properties: {
                type: Object,
                default: () => ({
                    isRegoAlwaysLookup: false,
                    isRegoLookup: false,
                    isRegoLookupWithCompliance: false,
                    isVinAlwaysLookup: false,
                    isVinLookup: false,
                    isVinLookupWithCompliance: false,
                    isFromIag:false,
                  isFromEio:false,
                })
            },

        },
        data: function () {
            return {
                newVehicleId: null,
                isDisabledBody: true,
                isAddNewVehicleToCurrentCustomer: false,
                newVehicleInfo: {
                    customer_vehicle_id: null,
                    dom: '',
                    complianceDate: '',
                    rego_number: '',
                    make: '',
                    model: '',
                    series: '',
                    series_shedule_number: '',
                    colour: '',
                    colour_variant: '',
                    badge: '',
                    body: '',
                    vin: '',
                    odometer: 0,
                    engineSize: null,
                    transmission: '',
                    cylinders: null,
                },
                selectedVehicleSheduleNumber: '',
                selectedCustomerVehicles: [],

            };
        },
        validations: {
            card: {
                vehicle: {
                    rego_number: {
                        required,
                    },
                    make: {
                        required,
                    },
                    model: {
                        required,
                    },
                }
            },
        },
        methods: {
          onlyNumber (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) {
              event.preventDefault();
            }
          },
          onlyNumbersAndPoint (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57) && event.key !== '.') {
              event.preventDefault();
            }
          },
            changedColour() {
              if (!this.isRSEstimateMethodology && !this.isEstimateMethodologyCustom) {
                this.$emit('changedColour', this.computedVehiclePaintCode.group)
              }
              this.autoUpdateCardVehicle();
            },
            beforeDebouncedAutoSave: function () {
                this.$emit('beforeDebouncedAutoSave');
            },
            beforeDebouncedUnsavedChanges: function () {
                this.$emit('beforeDebouncedUnsavedChanges');
            },
            loadTimesForItemPart: function () {
                this.$emit('loadTimesForItemPart');
            },
            //autoUpdateCardVehicle: function (e) {
            //    this.$emit('autoUpdateCardVehicle', e);
            //},
            checkErrorForCopyToEstimate: function () {
                // console.log('start')
                this.$v.card.vehicle.$touch();
                let vm = this;
                if (!this.isNoNeedVehicleRego) {
                    if (this.$v.card.vehicle.rego_number.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleRegoNumber.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        // console.log('start1')
                        return false;
                    } else if (this.$v.card.vehicle.make.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleMake.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        // console.log('start2')
                        return false;
                    } else if (this.$v.card.vehicle.model.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleModel.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        //  console.log('start3')
                        return false;
                    }
                }
                return true;
            },
            checkErrorForNewCard: function () {
                this.$v.card.vehicle.$touch();
                let vm = this;
                if (!this.isNoNeedVehicleRego) {
                    if (this.$v.card.vehicle.rego_number.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleRegoNumber.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        // console.log('start1')
                        return false;
                    } else if (this.$v.card.vehicle.make.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleMake.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        // console.log('start2')
                        return false;
                    } else if (this.$v.card.vehicle.model.$anyError) {
                        setTimeout(function () {
                            vm.$refs.errorTextCardVehicleModel.scrollIntoView({block: "center", inline: "center"});
                        }, 150);
                        //  console.log('start3')
                        return false;
                    }
                }
                return true;
            },
            setUpdateData: function (data) {
                this.$emit('setUpdateData', data);
            },
            loadPaintCodes: function () {
                this.$emit('loadPaintCodes');
            },
            labelVehiclePaintCode: function (t) {
                if (t.colour_name == '') {
                    return t.oem_paint_code + ' - ' + t.group;
                }
                return t.oem_paint_code + ' - ' + t.colour_name + ' - ' + t.group;
            },
            onCustomerVehicleRegoUpdate() {
              console.log("onUpdate")
              this.autoUpdateCardVehicle();
              this.loadPaintCodes();
              this.$emit('onCustomerVehicleSelect', false);
            },
            onCustomerVehicleSelect: function (v) {
              console.log('v', v)
                // this.showVehicleSearchBox = false
                if (v === null) {
                    //this.isNewVehicleInfo = true;
                    this.isAddNewVehicleToCurrentCustomer = true
                    let rego = this.card.vehicle.rego_number;
                    this.newVehicleInfo.rego_number = rego;
                    // this.card.vehicle = this.newVehicleInfo;
                } else {
                    console.log('onCustomerVehicleSelect', v);
                    this.card.vehicle.customer_vehicle_id = v.customer_vehicle_id;

                    if (v.rego_number) {
                        this.card.vehicle.rego_number = v.rego_number;
                    }
                    if (v.dom) {
                        this.card.vehicle.dom = v.dom;
                    }
                    if (v.compliancePlate && v.compliancePlate != '') {
                        this.card.vehicle.complianceDate = v.compliancePlate;
                    }
                    if (v.make) {
                        this.card.vehicle.make = v.make;
                        // this.selectedVehicleModel = v.model;
                    }
                    if (v.model) {
                        this.selectedVehicleMake = v.make;
                        // this.card.vehicle.model = v.model;
                    }
                    if (v.series) {
                        this.card.vehicle.series = v.series;
                    }
                    if (v.engineSize) {
                      this.card.vehicle.engineSize = v.engineSize;
                    }
                    if (v.cylinders) {
                      this.card.vehicle.cylinders = v.cylinders;
                    }
                    if (v.series_shedule_number != '') {
                        this.card.vehicle.series_shedule_number = v.series_shedule_number;
                        this.selectedVehicleSheduleNumber = v.series_shedule_number;
                    } else if (v.series) {
                        this.card.vehicle.series_shedule_number = this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][v.series]['data'].sn;
                        this.selectedVehicleSheduleNumber = this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][v.series]['data'].sn;
                    }
                    if (v.color && v.color != '') {
                        this.card.vehicle.colour = v.color;
                    }

                    if (v.colour_variant) {
                        this.card.vehicle.colour_variant = v.colour_variant;
                    }

                    if (v.badge) {
                        this.card.vehicle.badge = v.badge;
                    }

                    if (v.body) {
                        this.card.vehicle.body = v.body;
                    }

                    if (v.vin) {
                        this.card.vehicle.vin = v.vin;
                    }

                    if (v.odometer) {
                        this.card.vehicle.odometer = v.odometer;
                    }

                    if (v.transmission) {
                        this.card.vehicle.transmission = v.transmission;
                    }
                    this.setEngineSizeAndCylinders()
                }
                this.autoUpdateCardVehicle();
                this.loadPaintCodes();
                this.$emit('onCustomerVehicleSelect', this.isNewVehicleInfo);
            },
            onCustomerVehicleMakeSelect2: function (m) {
                this.card.vehicle.model = '';
                this.card.vehicle.body = '';
                this.card.vehicle.series = '';
                this.card.vehicle.series_shedule_number = '';
                this.selectedVehicleMake = m;
                if (m !== null) {
                    this.card.vehicle.make = m;
                }
            },
            onCustomerVehicleModelSelect2: function (m) {
                this.card.vehicle.series = '';
                this.selectedVehicleModel = m;
                if (m !== null) {
                    this.card.vehicle.model = m;
                }
            },
            onCustomerVehicleSeriesSelect2: function (s) {
                if (s !== null) {
                    this.card.vehicle.series = s;
                }
            },
            onCustomerVehicleMakeSelect: function (m) {
                this.card.vehicle.model = '';
                this.card.vehicle.body = '';
                this.card.vehicle.series = '';
                this.card.vehicle.engineSize = null;
                this.card.vehicle.cylinders = null;
                if (m.t == 2) {
                    this.card.vehicle.series_shedule_number = 0;
                } else {
                    this.card.vehicle.series_shedule_number = '';
                }
                this.selectedVehicleMake = m.key.trim();
                if (m !== null) {
                    this.card.vehicle.make = m.key.trim();
                }
                if (!this.isEstimateMethodologyCustom) {
                    this.card.vehicle.colour = '';
                }
                this.loadPaintCodes();
            },
            onCustomerVehicleModelSelect: function (m) {
                this.card.vehicle.series = m.value.trim();
                if (m.t == 2) {
                    this.card.vehicle.series_shedule_number = 0;
                } else {
                    this.card.vehicle.series_shedule_number = m.sn;
                }
                this.setEngineSizeAndCylinders()

                this.selectedVehicleSheduleNumber = m.sn;
                this.selectedVehicleModel = m.key.trim();
                this.card.vehicle.model = m.key.trim();
                if (this.computedBodies.length > 1) {
                    this.card.vehicle.body = '';
                }
            },
            onCustomerVehicleSeriesSelect: function (s) {
                try {
                    if (s !== null) {
                        this.card.vehicle.series = s.key;
                        this.card.vehicle.series_shedule_number = s.sn;
                        this.setEngineSizeAndCylinders()
                        this.selectedVehicleSheduleNumber = s.sn;
                        if (this.computedBodies.length > 1) {
                            this.card.vehicle.body = '';
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            },
          setEngineSizeAndCylinders() {
            let v = this.card.vehicle.series_shedule_number
            if (v) {
              if (v == 'TE01' || v == 'TE02' || v == 'TE03' || v == 'TE04' || v == 'TE05' ||
                  v == 'M1024' || v == 'BY01' || v == 'BY05' || v == 'BY06' || v == 'CU001' ||
                  v == 'GW14' || v == 'H718' || v == 'H750' || v == 'H727' || v == 'H670' ||
                  v == 'K171' || v == 'K190' || v == 'K174' || v == 'M956' || v == 'V239' ||
                  v == 'V235' || v == 'V236' || v == 'P052'
              ) {
                this.card.vehicle.engineSize = '0.0'
                this.card.vehicle.cylinders = '0'
                return true
              }
            }

              this.card.vehicle.engineSize = ''
              this.card.vehicle.cylinders = ''

          },
            onCustomerVehicleScheduleNumberSelect: function (s) {
                try {
                    if (s !== null) {
                        this.card.vehicle.series_shedule_number = s.sn;
                        this.selectedVehicleSheduleNumber = s.sn;
                        this.setEngineSizeAndCylinders()
                        if (!this.isNoScheduleNumberForSeries(this.card.vehicle.series) && !this.isNoScheduleNumberForModel(this.card.vehicle.model)) {
                            console.log('normal');
                            this.card.vehicle.model = s.model;
                            this.card.vehicle.series = s.series;
                        } else {
                            console.log('no schedule', this.isNoScheduleNumberForModel(this.card.vehicle.model), this.isNoScheduleNumberForSeries(this.card.vehicle.series));
                            this.currentNewScheduleNumber = s.sn;
                            //  this.addNewCustomNtarLtarVehicleScheduleNumber();
                        }

                        console.log('this.card.vehicle.series_shedule_number', this.card.vehicle.series_shedule_number);
                        if (this.computedBodies.length > 1) {
                            this.card.vehicle.body = '';
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            onCustomerVehicleBodySelect: function (b) {
                if (b !== null) {
                    this.card.vehicle.body = b;
                }
            },
            autoUpdateVehicleTotalLoss: function (event) {
                if (this.card.isTotalLoss == "true") {
                    this.card.isTotalLoss = true;
                }
                if (this.card.isTotalLoss == "false") {
                    this.card.isTotalLoss = false;
                }
                let update = {};
                update.isTotalLoss = this.card.isTotalLoss;
                this.setUpdateData(update);
            },
            autoUpdateCardVehicle: function (event) {
                if (!this.isAutoSave || this.isNewCard) {
                    return;
                }
                //console.log('autoUpdateCardVehicle', 'this.isNewVehicleInfo', this.isNewVehicleInfo, 'this.newVehicleId', this.newVehicleId);
                if (this.isNewVehicleInfo || this.isAddNewVehicleToCurrentCustomer) {
                    if (this.newVehicleId) {
                        this.card.vehicle.customer_vehicle_id = this.newVehicleId;
                    }
                    if (this.card.vehicle.customer_vehicle_id == null || this.card.vehicle.customer_vehicle_id == 0 || this.isAddNewVehicleToCurrentCustomer) {
                        this.$parent.isAutoSave = false;
                        let card_id = this.card.card_id;
                        Axios({
                            method: 'post',
                            responseType: 'json',
                            headers: {'addNewVehicleInfo': true},
                            url: `/fe/card/${card_id}`,
                            validateStatus: function (status) {
                                return status < 500;
                            },
                            data: {vehicle: this.card.vehicle, customer_id: this.card.customer.customer_id, not_rego: this.isNoNeedVehicleRego}
                        })
                            .then(response => {
                                console.log('response ',response.data, response.data.status )
                                if (response.data && response.data.status && response.data.status == true) {
                                    if (Number(response.data.result.id) > 0) {
                                        // this.$store.dispatch('loadCustomerVehicles');
                                        this.card.vehicle.customer_vehicle_id = response.data.result.id;
                                        this.card.customer.customer_id = response.data.result.customer_id;
                                        this.newVehicleId = response.data.result.id;
                                        this.newCustomerId = response.data.result.customer_id;
                                        this.newVehicleInfo = this.card.vehicle;
                                        this.selectedCustomerVehicles = [];
                                       //this.$parent.updateData.vehicle = null;
                                        //console.log('response.data.result.id ',response.data.result.id )
                                        this.$nextTick(() => {
                                            //console.log('saveAutoSave')
                                            this.$parent.autoSaveMessage = 'Saved';
                                        });
                                    } else {
                                        this.$parent.autoSaveMessage = 'Unsaved Changes';
                                    }
                                } else {
                                    this.$parent.autoSaveMessage = 'Unsaved Changes';
                                }
                                this.$parent.isAutoSave = true;
                            })
                            .catch(error => {
                                console.log('error',error)
                                this.$parent.autoSaveMessage = 'Unsaved Changes';
                                this.$parent.isAutoSave = true;
                            });
                    } else {
                        let update = {};
                        if (this.isNewVehicleInfo && this.newVehicleId > 0) {
                            update.existing_vehicle = true;
                        }
                        this.newVehicleInfo = this.card.vehicle;
                        update.vehicle = this.card.vehicle;
                        update.customer_id = this.card.customer.customer_id;
                        update.not_rego = this.isNoNeedVehicleRego;
                        this.setUpdateData(update);
                    }
                    this.isAddNewVehicleToCurrentCustomer = false
                } else {
                    let update = {};
                    update.vehicle = this.card.vehicle;
                    update.customer_id = this.card.customer.customer_id;
                    update.not_rego = this.isNoNeedVehicleRego;
                    this.setUpdateData(update);
                }
            },
            isNoScheduleNumberForMake: function (make) {
                if (make && this.vehicleMakeModels[make] && this.vehicleMakeModels[make].t && this.vehicleMakeModels[make].t == 2) {
                    return true;
                }
                return false;
            },
            isNoScheduleNumberForModel: function (model) {
                if (!model) {
                    return null;
                }
                if (model
                    && this.vehicleMakeModels[this.card.vehicle.make]
                    && this.vehicleMakeModels[this.card.vehicle.make]['data']
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][model]
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][model].t
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][model].t == 2) {
                    return true;
                }
                return false;
            },
            isNoScheduleNumberForSeries: function (series) {
                if (!series) {
                    return null;
                }
                if (series
                    && this.vehicleMakeModels[this.card.vehicle.make]
                    && this.vehicleMakeModels[this.card.vehicle.make]['data']
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data']
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][series]
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][series].t
                    && this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][series].t == 2) {
                    return true;
                }
                return false;
            },
            onCustomerVehiclePaintCodeNew: function (value) {
                this.currentNewPaintCode = _.toUpper(value);
            },
            onCustomerVehicleMakeNew: function (value) {
                this.currentNewMake = _.toUpper(value);
            },
            onCustomerVehicleModelNew: function (value) {
                this.currentNewModel = _.toUpper(value);
            },
            onCustomerVehicleSeriesNew: function (value) {
                this.currentNewSeries = _.toUpper(value);
            },
            onCustomerVehicleScheduleNumberNew: function (value) {
                this.currentNewScheduleNumber = _.toUpper(value);
            },
            noNewCustomNtarLtarVehicleMake: function () {
                this.currentNewMake = '';
                this.$refs.multimake.isOpen = false;
                this.$refs.multimake.$el.blur();
            },
            addNewVehiclePaintCode: function () {
                var arr = {};
                arr = {id: 0, colour_name: this.currentNewPaintCode, group: '', make: '', oem_paint_code: ''};
                this.computedVehiclePaintCode = arr;
                this.$refs.multipaintcode.isOpen = false;
                this.$refs.multipaintcode.$el.blur();
                this.currentNewPaintCode = '';
                //let currentEM = this.estimate.rates.estimate_methodology;
                //this.estimate.rates.paint_material = this.defaultValues.rates[currentEM].default_rates_paint_material;
                this.autoUpdateCardVehicle();
            },
            noNewVehiclePaintCode: function () {
                this.$refs.multipaintcode.isOpen = false;
                this.$refs.multipaintcode.$el.blur();
                this.currentNewPaintCode = '';
            },
            addNewCustomNtarLtarVehicleMake: function () {
                try {
                    if (this.currentNewMake != '') {
                        this.card.vehicle.model = '';
                        this.card.vehicle.series = '';
                        this.card.vehicle.series_shedule_number = 0;
                        var arr = {};
                        arr[this.currentNewMake] = {t: 2, data: {}};
                        var obj = Object.assign({}, this.$store.state.vehicleMakeModels);
                        obj = Object.assign({}, obj, arr);
                        this.$store.state.vehicleMakeModels = Object.assign({}, obj);
                        this.card.vehicle.make = this.currentNewMake;
                        this.selectedVehicleMake = this.currentNewMake;
                        this.currentNewMake = '';
                        this.$refs.multimake.isOpen = false;
                        this.$refs.multimake.$el.blur();
                        this.autoUpdateCardVehicle();
                        //this
                    }
                } catch (e) {
                    console.log('addNewCustomNtarLtarVehicleMake');
                    console.log(e);
                }
            },
            noNewCustomNtarLtarVehicleModel: function () {
                this.$refs.multimodel.isOpen = false;
                this.$refs.multimodel.$el.blur();
                this.currentNewModel = '';
            },
            addNewCustomNtarLtarVehicleModel: function () {
                try {
                    if (this.currentNewModel != '') {
                        this.card.vehicle.series = '';
                        this.card.vehicle.series_shedule_number = 0;
                        var arr = {};
                        arr[this.currentNewModel] = {t: 2, data: {}};

                        var obj = Object.assign({}, this.$store.state.vehicleMakeModels);
                        obj[this.card.vehicle.make]['data'] = Object.assign({}, obj[this.card.vehicle.make]['data'], arr);
                        this.$store.state.vehicleMakeModels = Object.assign({}, obj);
                        this.card.vehicle.model = this.currentNewModel;
                        this.selectedVehicleModel = this.currentNewModel;
                        this.$refs.multimodel.isOpen = false;
                        this.$refs.multimodel.$el.blur();
                        this.currentNewModel = '';
                        this.autoUpdateCardVehicle();
                    }
                } catch (err) {
                    console.log('Error', err);
                }
            },
            noNewCustomNtarLtarVehicleSeries: function () {
                this.$refs.multiseries.isOpen = false;
                this.$refs.multiseries.$el.blur();
                this.currentNewSeries = '';
            },
            addNewCustomNtarLtarVehicleSeries: function () {
                try {
                    if (this.currentNewSeries != '') {
                        this.card.vehicle.series_shedule_number = 0;
                        var arr = {};
                        arr[this.currentNewSeries] = {t: 2, data: {body: [], d: "", n: this.currentNewSeries, s: this.currentNewSeries, sn: 0}};
                        var obj = Object.assign({}, this.$store.state.vehicleMakeModels);
                        obj[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'] = Object.assign({},
                            obj[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'],
                            arr);
                        this.$store.state.vehicleMakeModels = Object.assign({}, obj);
                        this.card.vehicle.series = this.currentNewSeries;
                        this.$refs.multiseries.isOpen = false;
                        this.$refs.multiseries.$el.blur();
                        this.currentNewSeries = '';
                        this.autoUpdateCardVehicle();
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            noNewCustomNtarLtarVehicleScheduleNumber: function () {
                this.$refs.multischedulenumber.isOpen = false;
                this.$refs.multischedulenumber.$el.blur();
                this.currentNewScheduleNumber = '';
            },
            addNewCustomNtarLtarVehicleScheduleNumber: function () {
                try {
                    this.currentNewScheduleNumber = _.toUpper(_.trim(this.currentNewScheduleNumber));
                    if (this.currentNewScheduleNumber != '') {
                        this.card.vehicle.series_shedule_number = this.currentNewScheduleNumber;
                        this.setEngineSizeAndCylinders()
                        //this.$store.state.vehicleMakeModels[this.card.vehicle.make].data[this.card.vehicle.model].t = 1;
                        //this.$store.state.vehicleMakeModels[this.card.vehicle.make].data[this.card.vehicle.model].data[this.card.vehicle.series].t = 1;
                        // this.$store.state.vehicleMakeModels[this.card.vehicle.make].data[this.card.vehicle.model].data[this.card.vehicle.series].data.sn = this.currentNewScheduleNumber;
                        let arr = {};
                        arr[this.currentNewScheduleNumber] = {
                            model: this.card.vehicle.model,
                            series: this.card.vehicle.series,
                            sn: this.currentNewScheduleNumber
                        };
                        let obj = Object.assign({}, this.$store.state.vehicleScheduleNumbers);
                        obj[this.card.vehicle.make] = Object.assign({},
                            obj[this.card.vehicle.make],
                            arr);

                        this.$store.state.vehicleScheduleNumbers = Object.assign({}, obj);

                        let update = {};
                        update.new_schedule_number = {
                            schedule_number: this.currentNewScheduleNumber,
                            model: this.card.vehicle.model,
                            make: this.card.vehicle.make,
                            series: this.card.vehicle.series,
                        };
                        this.setUpdateData(update);

                        this.currentNewScheduleNumber = '';
                        this.noNewCustomNtarLtarVehicleScheduleNumber();
                        // this.autoUpdateNewScheduleNumber();
                        this.autoUpdateCardVehicle();

                    }
                } catch (err) {
                    console.log(err);
                }
            },
            nameOfVehicleMake({key, value}) {
                if (key) {
                    return key;
                }
                return '';
            },
            nameOfVehicleModel({key, value}) {
                if (key) {
                    return `${key}`;
                }
                return '';
            },
            nameOfVehicleSeries({key, value}) {
                if (key) {
                    return `${key}`;
                }
                return '';
            },
            nameOfVehicleScheduleNumber({sn, model, series}) {
                if (sn) {
                    let name = `${sn}` + ' - ' + `${model}` + ' - ' + `${series}`;
                    if (this.isRSEstimateMethodology) {
                        name += ' - RS';
                        // console.log('nameOfVehicleScheduleNumber', name);
                    }
                    return name;
                }
                return '';
            },
            onVehiclePaintCodesSelect: function (t) {
                this.card.vehicle.colour = t.oem_paint_code + ' - ' + t.colour_name + ' - ' + t.group;
            },
        },

        computed: {
            ...mapGetters({
              vehicleTransmissions:'vehicleTransmissions',
              isStatusActive:'isStatusActive',
              isRsEnabled:'isRsEnabled',
              customerVehicles: 'getAllCustomers',

              'isTechnicalUser': 'isTechnicalUser',
              'isCustomerUser': 'isCustomerUser',
              'isEstimatorUser': 'isEstimatorUser',
              'isProductionManagerUser': 'isProductionManagerUser',
              'isPDRTechnicianUser': 'isPDRTechnicianUser',
              'isShopManagerUser': 'isShopManagerUser',
              'isPanelTechnicianUser': 'isPanelTechnicianUser',
              'isPaintTechnicianUser': 'isPaintTechnicianUser',
              'isStripperFitterUser': 'isStripperFitterUser',
              'isDetailerUser': 'isDetailerUser',
            }),
            isDisabledFieldsByUserRole() {
              return  this.isPanelTechnicianUser || this.isPaintTechnicianUser || this.isStripperFitterUser || this.isDetailerUser || this.isTechnicalUser || this.isPDRTechnicianUser
            },
            isDisableEditRego() {
              return false;
               // return Boolean(this.properties.isFromIag)
            },
            stateForLookUp() {
                if (this.card.customer.customer_type === 'I') {
                    return this.card.customer.state;
                }
                return '';
            },
            isActiveLookupAlwaysOn() {
                return !!(this.properties && this.properties.isRegoAlwaysLookup);
            },
            isActiveLookup() {
                return !!(this.properties && !this.isActiveLookupAlwaysOn && this.properties.isRegoLookup);
            },
            isActiveLookupWithCompliance() {
                return !!(this.properties && !this.isActiveLookupAlwaysOn && this.properties.isRegoLookupWithCompliance);
            },
            isRSEstimateMethodology() {
                return !!(this.isRsEnabled && this.propsEstimateMethodology == 'rs');
            },
            isZurichEstimateMethodology() {
              return !!(this.propsEstimateMethodology == 'zi');
            },
            computedVehicleTransmission: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.vehicleTransmissions, function (itm) {
                            return itm.key === vm.card.vehicle.transmission;
                        });
                        return item;
                    } catch (e) {
                        return [];
                    }
                },
                set: function (item) {
                    this.card.vehicle.transmission = item.key;
                },
            },
            showUpperRegoNumber: {
                get: function () {
                    return _.toUpper(this.card.vehicle.rego_number);
                },
                set: function (rego) {
                    rego = _.trim(rego);
                    this.card.vehicle.rego_number = _.toUpper(rego);
                }
            },
            isTechnicalUser: function () {
                let userInfo = this.$store.state.userInfo;
                if (userInfo && userInfo.group_id == 4) {
                    return true;
                }
                return false;
            },
            isAutoSave:
                {
                    get: function () {
                        return this.propsIsAutoSave;
                    },
                    set: function (v) {

                    }
                },
            isNewVehicleInfo: function () {
                if (this.card.vehicle.customer_vehicle_id == 0 || this.card.vehicle.customer_vehicle_id == null) {
                    return true;
                }
                return false;
            },
            isInsuranceRepairYes: function () {
                if (this.card.insurance_repair == 'true') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairNo: function () {
                if (this.card.insurance_repair == 'false') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairOther: function () {
                if (this.card.insurance_repair == 'other' || this.card.insurance_repair == '2') {
                    return true;
                }
                return false;
            },
            isNoNeedVehicleRego: function () {
                if (this.isInsuranceRepairOther) {
                    return true;
                }
                return false;
            },
            isShowRedMessageForBody: function () {
                if (this.computedBodies && this.computedBodies.length > 0 && this.card.vehicle.body === '') {
                    return true;
                }
                return false;
            },
            computedBodies: function () {
                try {
                    if (this.card.vehicle.series_shedule_number != '' && this.card.vehicle.make != '') {
                        let self = this;
                        let arr = _.find(this.$store.state.vehicleScheduleNumbers[this.card.vehicle.make],
                            {
                                sn: self.card.vehicle.series_shedule_number,
                            }
                        );
                        if (arr) {
                            let series = _.toUpper(arr.series);
                            let model = _.toUpper(arr.model);
                            let bodies = self.vehicleMakeModels[self.card.vehicle.make]['data'][model]['data'][series]['data'].body;
                            if (bodies.length == 1) {
                                this.card.vehicle.body = bodies[0];
                            }
                            return bodies;
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
                return [];
            },
            computedSheduleNumber: function () {
                if (this.card.insurance_repair == 'true' && !this.isEstimateMethodologyCustom) {
                    if (this.card.vehicle.make != '' && this.card.vehicle.model != '' && this.card.vehicle.series != '') {
                        try {
                            let sn = this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'][this.card.vehicle.series]['data'].sn;
                            this.card.vehicle.series_shedule_number = sn;
                            return sn;
                        } catch (e) {
                            //console.log(e);
                        }
                    }
                }
                return '';
            },
            computedVehiclePaintCodes: function () {
                try {
                    if (this.vehiclePaintCodes && Array.isArray(this.vehiclePaintCodes)) {
                        return this.vehiclePaintCodes;
                    }
                } catch (err) {
                    console.log('computedVehiclePaintCodes', err);
                }
                console.log('not computed paint codes');
                return [];
            },
            computedVehiclePaintCode: {
                get: function () {
                    try {
                        let arr = _.split(this.card.vehicle.colour, ' - ');
                        if (arr && arr.length == 3) {
                            return {oem_paint_code: arr[0], colour_name: arr[1], group: arr[2]};
                        } else {
                            return {oem_paint_code: '', colour_name: this.card.vehicle.colour, group: ''};
                        }
                    } catch (e) {
                        console.log('computedVehiclePaintCode', e);
                    }
                    return '';
                },
                set: function (pc) {
                    if (pc.oem_paint_code == '' && pc.group == '') {
                        this.card.vehicle.colour = pc.colour_name;
                    } else {
                        this.card.vehicle.colour = pc.oem_paint_code + " - " + pc.colour_name + " - " + pc.group;
                    }
                }
            },
            isEstimateMethodologyCustom: function () {
                if (this.propsEstimateMethodology !== undefined && this.propsEstimateMethodology !== null) {
                    if (this.propsEstimateMethodology === 'custom') {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.propsEstimate !== undefined && this.propsEstimate !== null) {
                    if (this.propsEstimate.rates.estimate_methodology === 'custom') {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.card.insurance !== undefined && this.card.insurance !== null && this.card.insurance.rates !== undefined && this.card.insurance.rates !== null) {
                    if (this.card.insurance.rates.estimate_methodology === 'custom') {
                        return true;
                    } else {
                        return false;
                    }
                }
                return false;
            },
            vehicleMakeModels2: function () {
                return this.$store.state.vehiclesForCustom;
            },
            vehicleMakes2: function () {
                try {
                    return _.orderBy(_.keys(this.vehicleMakeModels2));
                } catch (erro) {
                    return [];
                }
            },
            vehicleModels2: function () {
                try {
                    return _.orderBy(_.keys(this.vehicleMakeModels2[this.card.vehicle.make]));
                } catch (erro) {
                    return [];
                }
            },
            vehicleSeries2: function () {
                try {
                    return _.orderBy(_.values(this.vehicleMakeModels2[this.card.vehicle.make][this.card.vehicle.model]));
                } catch (erro) {
                    console.log(erro);
                    return [];
                }
            },
            selectedCustomerVehicles2: function () {
                try {
                    let index = _.findIndex(this.customerVehicles, ['customer_id', this.card.customer.customer_id]);
                    let vehicles = this.customerVehicles[index].vehicles;
                    return vehicles;
                } catch (erro) {
                    return [];
                }
            },
            vehicleMakeModels: {
                get: function () {
                    return this.$store.state.vehicleMakeModels;
                },
                set: function (obj) {
                },
            },
            vehicleMakes: function () {
                try {
                    var _result = [];
                    _.forEach(this.vehicleMakeModels, function (value, key) {
                        if (value['t'] == 2) {
                            _result.push({key: key, t: 2});
                        } else {
                            _result.push({key: key, t: 1});
                        }
                    });
                    return _.sortBy(_result, ['key']);
                } catch (erro) {
                    console.log(erro);
                    return [];
                }
            },
            vehicleModels: {
                get: function () {
                    try {
                        var _result = [];
                        if (!_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]) && !_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]['data'])) {
                            _.forEach(this.vehicleMakeModels[this.card.vehicle.make]['data'], function (value, key) {
                                if (!_.isEmpty(value['data'])) {
                                    _.forEach(value['data'], function (value2) {
                                        if (value['t'] == 2) {
                                            _result.push({key: key, value: value2['data'].n, sn: 0, t: 2});
                                        } else {
                                            _result.push({
                                                key: key,
                                                value: value2['data'].n.trim(),
                                                sn: value2['data'].sn,
                                                t: 1
                                            });
                                        }
                                    });
                                } else {

                                    _result.push({key: key, value: '', sn: 0, t: 2});

                                }
                            });
                        }
                        return _.sortBy(_result, ['key']);
                    } catch (erro) {
                        console.log('Error', erro);
                        return [];
                    }
                },
                cache: false,
            },
            vehicleSeries: function () {
                console.log('vehicleSeries');
                try {
                    var _result = [];
                    if (!_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]) && !_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]['data'])
                        && !_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model])
                        && !_.isEmpty(this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'])) {
                        _.forEach(this.vehicleMakeModels[this.card.vehicle.make]['data'][this.card.vehicle.model]['data'], function (value, key) {

                            if (value['t'] == 2) {
                                _result.push({key: key, sn: 0, t: 2});
                            } else {
                                _result.push({key: key, sn: value['data'].sn, t: 1});
                            }
                        });
                    }
                    return _.sortBy(_result, ['key']);
                } catch (erro) {
                    console.log(erro);
                    return [];
                }
            },
            vehicleScheduleNumbers: function () {
                console.log('vehicleScheduleNumbers');
                try {
                    return _.sortBy(_.values(this.$store.state.vehicleScheduleNumbers[this.card.vehicle.make]), ['sn']);
                } catch (erro) {
                    console.log(erro);
                    return [];
                }
            },
            customerVehicleMake: {
                get: function () {
                    if (this.card.vehicle.series_shedule_number === 0) {
                        return {key: this.card.vehicle.make, t: 2};
                    } else {
                        return {key: this.card.vehicle.make, t: 1};
                    }
                },
                set: function (item) {
                    if (item && item.key) {
                        this.card.vehicle.make = item.key;
                    } else {
                        this.card.vehicle.make = item;
                    }
                },
            },
            customerVehicleModel: {
                get: function () {
                    if (this.card.vehicle.series_shedule_number === 0) {
                        return {key: this.card.vehicle.model, value: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 2};
                    } else {
                        return {key: this.card.vehicle.model, value: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 1};
                    }
                },
                set: function (item) {
                    this.card.vehicle.model = item.key;
                },
            },
            customerVehicleSeries: {
                get: function () {
                    if (this.card.vehicle.series_shedule_number === 0) {
                        return {key: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 2};
                    } else {
                        return {key: this.card.vehicle.series, sn: this.card.vehicle.series_shedule_number, t: 1};
                    }
                },
                set: function (item) {
                    // this.card.vehicle.model = item.key;
                },
            },
            customerVehicleScheduleNumber: {
                get: function () {
                    try {
                        // let sn = this.card.vehicle.series_shedule_number;
                        let self = this;

                        let arr = _.find(this.$store.state.vehicleScheduleNumbers[this.card.vehicle.make],
                            {
                                sn: self.card.vehicle.series_shedule_number,
                                //    series: self.card.vehicle.series,
                                //     model: self.card.vehicle.model,
                            }
                        );
                        console.log('customerVehicleScheduleNumber, getArr:', arr, self.card.vehicle.series_shedule_number);
                        if (arr) {
                            return arr;
                        }
                    } catch (err) {
                        console.log('customerVehicleScheduleNumber, get:', err);
                    }
                    return {};
                },
                set: function (item) {

                    console.log('set SN:', item);
                },
            },
        },
        watch: {},
        components: {
            DatePicker,
            AdvancedSearch,
            NumberFormatter,
            Multiselect,
            AdvancedSearchForLookup,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue" v-if="!isNewCard">
            <strong>Vehicle</strong>
        </div>
        <div class="card-block bg-white">

            <div class="form-group row">
                <label for="rego_number" class="col-sm-4 col-4 col-form-label">Rego<span class="indicate" v-if="isFromPlanner">*</span></label>
                <div class="col-sm-8 col-8 wrapper-advenced-search-for-lookup">
                    <advanced-search-for-lookup ref="rego_number"
                                                v-model="showUpperRegoNumber"
                                                :searchData="selectedCustomerVehicles2"
                                                :searchKey="'search'"
                                                :searchLabel="'Rego Number'"
                                                placeholder="Rego number"
                                                :isNewCard="isNewCard"
                                                :isCardEdit="isCardEdit"
                                                :isEstimateEdit="isEstimateEdit"
                                                id="vehicle.rego_number"
                                                :isDisabled="isDisableEditRego || !isStatusActive || isDisabledFieldsByUserRole"
                                                @onChooseSearchWithLookup="value => isDisabledBody = value"
                                                @onUpdate="onCustomerVehicleRegoUpdate"
                                                :isActiveLookup="isActiveLookup"
                                                :isActiveLookupAlwaysOn="isActiveLookupAlwaysOn"
                                                :isActiveLookupWithCompliance="isActiveLookupWithCompliance"
                                                :state="stateForLookUp"
                                                @onSelect="onCustomerVehicleSelect"
                                                :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}">
                    </advanced-search-for-lookup>
                    <small class="form-text show-help-for-body" v-if="!isNoNeedVehicleRego && $v.card.vehicle.rego_number.$anyError" ref="errorTextCardVehicleRegoNumber">
                        Vehicle Rego number is missing
                    </small>

                </div>
            </div>
            <div class="form-group row">
                <label for="make" class="col-sm-4 col-4 col-form-label">Make</label>
                <div class="col-sm-8 col-8" ref="multi1">
                    <multiselect
                            ref="multimake"
                            id="multimake"
                            v-model="customerVehicleMake"
                            :options="vehicleMakes"
                            :custom-label="nameOfVehicleMake"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Make"
                            @select="onCustomerVehicleMakeSelect"
                            @input="autoUpdateCardVehicle"
                            @search-change="onCustomerVehicleMakeNew"
                            style="opacity: 1;"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard) || !isStatusActive || isDisabledFieldsByUserRole"
                    >
                        <template slot="noResult" slot-scope="props">
                            <div style="padding:10px; text-align: left; white-space: normal;">
                                <p>You are about to create an unscheduled vehicle.</p>
                                <p>Do you want continue?</p>
                                <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px; margin:0 10px 0 0;" @click="addNewCustomNtarLtarVehicleMake">
                                    Yes
                                </button>
                                <button class="btn btn-sm clickable" style="padding:5px 10px;" @click="noNewCustomNtarLtarVehicleMake">No
                                </button>
                            </div>
                        </template>
                        <template slot="singleLabel" slot-scope="props">
                            {{ props.option.key }}
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="isNoScheduleNumberForMake(props.option.key)"><span v-if="props.option.key">&nbsp- </span>No schedule number</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            {{props.option.key}}
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="props.option.t == 2">no schedule number</span>
                        </template>
                    </multiselect>
                    <small class="form-text show-help-for-body" ref="errorTextCardVehicleMake" v-if="!isNoNeedVehicleRego && $v.card.vehicle.make.$anyError">
                        Vehicle Make is missing
                    </small>
                </div>
            </div>
            <div class="form-group row">
                <label for="model" class="col-sm-4 col-4 col-form-label">Model</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            ref="multimodel"
                            id="multimodel"
                            v-model="customerVehicleModel"
                            :options="vehicleModels"
                            :custom-label="nameOfVehicleModel"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Model"
                            @select="onCustomerVehicleModelSelect"
                            @input="autoUpdateCardVehicle"
                            @search-change="onCustomerVehicleModelNew"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard) || !isStatusActive || isDisabledFieldsByUserRole"
                            style="opacity: 1"
                    >
                        <template slot="singleLabel" slot-scope="props">
                            {{ props.option.key }}
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="isNoScheduleNumberForModel(props.option.key)"><span v-if="props.option.key">&nbsp- </span>No schedule number</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span v-if="props.option.value != ''">{{ props.option.key}} - {{ props.option.value}}</span>
                            <span v-else>{{ props.option.key}}</span>
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="props.option.t == 2">no schedule number</span>
                        </template>
                        <template slot="noResult" slot-scope="props">
                            <div style="padding:10px; text-align: left;  white-space: normal;">
                                <p>You are about to create an unscheduled vehicle.</p>
                                <p>Do you want continue?</p>
                                <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px;  margin:0 10px 0 0;" @click="addNewCustomNtarLtarVehicleModel">
                                    Yes
                                </button>
                                <button class="btn btn-sm clickable" style="padding:5px 10px;" @click="noNewCustomNtarLtarVehicleModel">
                                    No
                                </button>
                            </div>
                        </template>
                    </multiselect>
                    <small class="form-text show-help-for-body" v-if="!isNoNeedVehicleRego && $v.card.vehicle.model.$anyError" ref="errorTextCardVehicleModel">
                        Vehicle Model number is missing
                    </small>
                </div>
            </div>
            <div class="form-group row">
                <label for="series" class="col-sm-4 col-4 col-form-label">Series</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            ref="multiseries"
                            id="multiseries"
                            v-model="customerVehicleSeries"
                            :options="vehicleSeries"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Series"
                            @select="onCustomerVehicleSeriesSelect"
                            @input="autoUpdateCardVehicle"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard) || !isStatusActive || isDisabledFieldsByUserRole"
                            style="opacity: 1;"
                            @search-change="onCustomerVehicleSeriesNew"
                    >
                        <template slot="singleLabel" slot-scope="props">
                            {{ props.option.key }}
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="isNoScheduleNumberForSeries(props.option.key)"><span v-if="props.option.key">&nbsp- </span>No schedule number</span>
                        </template>
                        <template slot="noResult" slot-scope="props">
                            <div style="padding:10px; text-align: left;  white-space: normal;">
                                <p>You are about to create an unscheduled vehicle.</p>
                                <p>Do you want continue?</p>
                                <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px; margin:0 10px 0 0;" @click="addNewCustomNtarLtarVehicleSeries">
                                    Yes
                                </button>
                                <button class="btn btn-sm clickable" style="padding:5px 10px;" @click="noNewCustomNtarLtarVehicleSeries">
                                    No
                                </button>
                            </div>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span>{{ props.option.key}}</span>
                            <span style="text-align: right; float:right; font-size:0.85rem;" v-if="props.option.t == 2">no schedule number</span>
                        </template>
                    </multiselect>
                </div>
            </div>

            <div v-if="!isEstimateMethodologyCustom && !isFromPlanner" class="form-group row" >
                <label class="col-sm-4 col-4 col-form-label">Schedule Number</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            ref="multischedulenumber"
                            id="multischedulenumber"
                            v-model="customerVehicleScheduleNumber"
                            :custom-label="nameOfVehicleScheduleNumber"
                            :options="vehicleScheduleNumbers"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Schedule Number"
                            @select="onCustomerVehicleScheduleNumberSelect"
                            @input="autoUpdateCardVehicle"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard) || !isStatusActive || isDisabledFieldsByUserRole"
                            style="opacity: 1;"
                            @search-change="onCustomerVehicleScheduleNumberNew"
                    >
                        <template slot="singleLabel"
                                  slot-scope="props">
                                    <span v-if="props.option.sn && isZurichEstimateMethodology">
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}} - ZI
                                    </span>
                          <span v-else-if="props.option.sn && isRSEstimateMethodology">
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}} - RS
                                    </span>
                          <span v-else-if="props.option.sn">
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}}
                                    </span>
                        </template>
                        <template slot="noResult" slot-scope="props">
                            <div style="padding:15px; text-align: left;  white-space: normal;"
                                 v-if="isNoScheduleNumberForModel(card.vehicle.model) || isNoScheduleNumberForSeries(card.vehicle.series)">
                                <p>You are about to create new schedule number for this vehicle.</p>
                                <p>Do you want continue?</p>
                                <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px; margin:0 10px 0 0;"
                                        @click="addNewCustomNtarLtarVehicleScheduleNumber">
                                    Yes
                                </button>
                                <button class="btn btn-sm clickable" style="padding:5px 10px;"
                                        @click="noNewCustomNtarLtarVehicleScheduleNumber">
                                    No
                                </button>
                            </div>
                        </template>
                        <template slot="option" slot-scope="props">
                          <span v-if="isZurichEstimateMethodology">
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}} - ZI
                                    </span>
                          <span v-else-if="isRSEstimateMethodology">
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}} - RS
                                    </span>
                          <span v-else>
                                     {{props.option.sn}} - {{props.option.model}} - {{props.option.series}}
                                    </span>
                        </template>
                    </multiselect>
                </div>
            </div>

            <div class="form-group row" v-if="isEstimateEdit">
              <label for="badge" class="col-sm-4 col-4 col-form-label">Engine Size</label>
              <div class="col-sm-8 col-8">
                <input type="text" v-model="card.vehicle.engineSize" class="form-control" placeholder="Engine Size" @change="autoUpdateCardVehicle" @keypress="onlyNumbersAndPoint"
                       :disabled="isDisabledFieldsByUserRole" :readonly="!isStatusActive" maxlength="4">
              </div>
            </div>

            <div class="form-group row" v-if="isEstimateEdit">
              <label for="badge" class="col-sm-4 col-4 col-form-label">Cylinders</label>
              <div class="col-sm-8 col-8">
                <input type="text" v-model="card.vehicle.cylinders" class="form-control" placeholder="Cylinders" @change="autoUpdateCardVehicle" @keypress="onlyNumber"
                       :disabled="isDisabledFieldsByUserRole" :readonly="!isStatusActive" maxlength="2">
              </div>
            </div>

            <div class="form-group row">
                <label for="badge" class="col-sm-4 col-4 col-form-label">Badge</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="card.vehicle.badge" class="form-control" id="badge" placeholder="Badge" @change="autoUpdateCardVehicle"
                           :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard ) || isDisabledFieldsByUserRole" :readonly="!isStatusActive">
                </div>
            </div>

            <div class="form-group row">
                <label for="body" class="col-sm-4 col-4 col-form-label">Body</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="card.vehicle.body" class="form-control" id="body" placeholder="Body" @change="autoUpdateCardVehicle"
                           :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && isDisabledBody) || isDisabledFieldsByUserRole" v-if="computedBodies.length <= 1"
                           :readonly="computedBodies.length == 1 || !isStatusActive">
                    <multiselect
                            v-model="card.vehicle.body"
                            :options="computedBodies"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Body"
                            v-if="computedBodies.length >  1"
                            @select="onCustomerVehicleBodySelect"
                            @input="autoUpdateCardVehicle"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard  && isDisabledBody) || !isStatusActive || isDisabledFieldsByUserRole"
                            style="opacity: 1"
                    ></multiselect>
                    <small class="form-text show-help-for-body" v-if="isShowRedMessageForBody">
                        Select the Body type for this vehicle
                    </small>
                </div>
            </div>

            <div v-if="!isFromPlanner" class="form-group row">
                <label for="dom" class="col-sm-4 col-4 col-form-label">Build Date</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="card.vehicle.dom" class="form-control" id="dom" placeholder="Build Date" :disabled="isDisabledFieldsByUserRole" @change="autoUpdateCardVehicle" :readonly="!isStatusActive">
                </div>
            </div>

            <div v-if="!isFromPlanner" class="form-group row">
                <label for="compliance_date" class="col-sm-4 col-4 col-form-label">Compliance Date</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="card.vehicle.complianceDate" :readonly="!isStatusActive" class="form-control" id="compliance_date" placeholder="Compliance Date" :disabled="isDisabledFieldsByUserRole"
                           @change="autoUpdateCardVehicle">
                </div>
            </div>

            <div v-if="!isFromPlanner" class="form-group row">
                <label for="vin" class="col-sm-4 col-4 col-form-label">VIN</label>
                <div class="col-sm-8 col-8 wrapper-advenced-search-for-lookup">
                    <input type="text" v-model="card.vehicle.vin" :readonly="!isStatusActive" class="form-control text-uppercase" id="vin" placeholder="VIN" :disabled="isDisabledFieldsByUserRole" @change="autoUpdateCardVehicle">
                    <!--                    <advanced-search-for-lookup ref="vehicle_vin"-->
                    <!--                                                v-model="card.vehicle.vin"-->
                    <!--                                                :searchData="selectedCustomerVehicles2"-->
                    <!--                                                :searchKey="'search'"-->
                    <!--                                                :searchLabel="'VIN'"-->
                    <!--                                                :isNewCard="isNewCard"-->
                    <!--                                                :isCardEdit="isCardEdit"-->
                    <!--                                                :isEstimateEdit="isEstimateEdit"-->
                    <!--                                                placeholder="VIN"-->
                    <!--                                                id="vin"-->
                    <!--                                                @onChooseSearchWithLookup="value => isDisabledBody = value"-->
                    <!--                                                :isVINField="true"-->
                    <!--                                                :isDisabled="isTechnicalUser || isNewCard"-->
                    <!--                                                :isActiveLookup="isActiveLookup"-->
                    <!--                                                :isActiveLookupWithCompliance="isActiveLookupWithCompliance"-->
                    <!--                                                :isActiveLookupAlwaysOn="isActiveLookupAlwaysOn"-->
                    <!--                                                @onSelect="onCustomerVehicleSelect">-->
                    <!--                    </advanced-search-for-lookup>-->
                </div>
            </div>

            <div v-if="!isFromPlanner" class="form-group row">
                <label for="colour" class="col-sm-4 col-4 col-form-label">Colour</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            ref="multipaintcode"
                            v-model="computedVehiclePaintCode"
                            :options="computedVehiclePaintCodes"
                            track-by="oem_paint_code"
                            label="oem_paint_code"
                            :custom-label="labelVehiclePaintCode"
                            :show-labels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Paint Code"
                            @select="onVehiclePaintCodesSelect"
                            @input="changedColour"
                            :disabled="(!card.vehicle.customer_vehicle_id && !isNoNeedVehicleRego && !isNewCard) || !isStatusActive || isDisabledFieldsByUserRole"
                            @search-change="onCustomerVehiclePaintCodeNew"
                            v-if="!isEstimateMethodologyCustom"
                            style="opacity: 1"
                    >
                        <template slot="noResult" slot-scope="props">
                            <div style="padding:15px; text-align: left;  white-space: normal;">
                                <p>You are about to create custom Paint Code.</p>
                                <p>Do you want continue?</p>
                                <button class="btn btn-sm btn-primary clickable" style="padding:5px 10px; margin:0 10px 0 0;"
                                        @click="addNewVehiclePaintCode">Yes
                                </button>
                                <button class="btn btn-sm clickable" style="padding:5px 10px;"
                                        @click="noNewVehiclePaintCode">No
                                </button>
                            </div>
                        </template>
                        <template slot="singleLabel" slot-scope="props">
                            <span v-if="props.option.oem_paint_code == ''">{{ props.option.colour_name }}</span>
                            <span v-else-if="props.option.colour_name == ''">{{ props.option.oem_paint_code }} - {{ props.option.group }}</span>
                            <span v-else>{{ props.option.oem_paint_code }} - {{ props.option.colour_name }} - {{ props.option.group }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <span v-if="props.option.oem_paint_code == ''">{{ props.option.colour_name }}</span>
                            <span v-else-if="props.option.colour_name == ''">{{ props.option.oem_paint_code }} - {{ props.option.group }}</span>
                            <span v-else>{{ props.option.oem_paint_code }} - {{ props.option.colour_name }} - {{ props.option.group }}</span>
                        </template>
                    </multiselect>
                    <input v-if="isEstimateMethodologyCustom" v-model="card.vehicle.colour"
                           @change="autoUpdateCardVehicle"
                           type="text"
                           :readonly="!isStatusActive"
                           class="form-control" placeholder="Colour"
                    >
                </div>
            </div>
            <div v-if="!isFromPlanner" class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Colour Variant</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="card.vehicle.colour_variant" :readonly="!isStatusActive" class="form-control" placeholder="Colour Variant" @change="autoUpdateCardVehicle" :disabled="isDisabledFieldsByUserRole">
                </div>
            </div>


            <div v-if="!isFromPlanner" class="form-group row">
                <label for="odometer" class="col-sm-4 col-4 col-form-label">Odometer</label>
                <div class="col-sm-8 col-8">
                    <number-formatter v-model="card.vehicle.odometer" type="text" class="form-control width50" id="odometer" placeholder="Odometer"
                                      :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                      format="0,0" @onChange="autoUpdateCardVehicle"></number-formatter>
                </div>
            </div>


            <div v-if="!isFromPlanner" class="form-group row">
                <label for="vehicleTransmission" class="col-sm-4 col-4 col-form-label">Transmission</label>
                <div class="col-sm-8 col-8">
                    <multiselect
                            ref="vehicleTransmission"
                            id="vehicleTransmission"
                            v-model="computedVehicleTransmission"
                            :options="vehicleTransmissions"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Vehicle Transmission"
                            track-by="key"
                            label="value"
                            @select="autoUpdateCardVehicle"
                            style="opacity: 1"
                            :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
</style>
