<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import DatePicker from 'v-calendar/src/components/DatePicker'

export default {
  name: 'date-picker',
  props: {
    isShowIcon: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Object,
      default() {
        return {}
      }
    },
    visibility: {
      type: String,
      default: 'click'
    },
    placeHolderText: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    updateData (val) {
      if (this.localValue === val && val ) {
        return
      }
      if (this.localValue == null && this.value) {
        this.localValue = ''
        return
      }
      this.$emit('input', val)
      this.$emit('onChange', {prev: this.localValue, now: val})
      this.localValue = val
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    }
  },
  created() {
    this.debouncedUpdateData = _.debounce((v) => {
      this.updateData(v)
    }, 500)
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (val) {
        return val
      }
    }
  },
  data () {
    return {
      masks: {
        input: 'DD/MM/YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'DD/MM/YYYY'
      },
      localValue: this.value ? this.value : null
    }
  },
}

</script>

<template>
  <DatePicker v-model="computedValue"
              :disabled-dates='_isEmpty(disabledDates) ? null : disabledDates'
              :attributes="[{key: 'today',highlight: !computedValue,dates: new Date(),},]"
              :masks="masks" :model-config="modelConfig" @input="debouncedUpdateData" :popover="{ visibility: visibility }">
    <template v-slot="{ inputValue, inputEvents }">
      <input
          class="form-control"
          :value="inputValue"
          v-on="inputEvents"
          :disabled="isDisabled"
          @keydown.down="$emit('onKeyPressDown')"
          @keydown.up="$emit('onKeyPressUp')"
          :readonly="isReadonly"
          :placeholder="placeHolderText"
          @change="debouncedUpdateData(inputValue)"
      />
      <i v-if="isShowIcon && inputValue" @click="$emit('iconClicked')" class="bx bxs-calendar" style="font-size: 22px; color: #1d4898; height: 0px; position: absolute; top: 4px; right: 20px; cursor: pointer"></i>
    </template>
  </DatePicker>
</template>
