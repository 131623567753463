var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-sm-4 col-sm-8 col-md-6 col-12 additional-left-position"},[_c('search-filter',{ref:"search-filter",attrs:{"placeholder":"Search or Filter results"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)]),_c('div',{staticClass:"timeline overflow-x-auto"},[(_vm.isOpenNewTaskForm)?_c('div',{staticClass:"tl-item tl-activity-item",attrs:{"data-activity-id":'newtask-1'}},[_c('div',{staticClass:"tl-wrap b-a-primary"},[_c('i',{staticClass:"fa fa-user left",attrs:{"aria-hidden":"true"}}),_c('activity-list-task',{attrs:{"task":_vm.newTask,"assigned-to-list":_vm.filteredListAssignedTo,"openNewTaskForm":_vm.isOpenNewTaskForm},on:{"save":_vm.saveNewTask,"close":_vm.closeNewTask}})],1)]):_vm._e(),_vm._l((_vm.computedActivityWithFilter),function(a,index){return _c('div',{key:a && a.activity_id ? 'activity-'+a.activity_id : _vm.getRandom(),class:[a.viewed === '1' ? 'tl-item tl-activity-item viewed' : 'tl-item tl-activity-item'],attrs:{"data-activity-id":a.activity_id}},[_c('div',{staticClass:"tl-wrap b-a-primary"},[(
                  (a.action_code == 'IPP'
                  || a.action_code == 'ICC'
                  || a.action_code == 'IEC'
                  || a.action_code == 'IFP'
                  || a.action_code == 'IEP'
                  || a.action_code == 'ICP'
                  || a.action_code == 'ExcessInvoiceFullPayment'
                  || a.action_code == 'ExcessInvoicePayment'
                  || a.action_code == 'CompleteInvoiceFullPayment'
                  || a.action_code == 'CompleteInvoicePayment'))?_c('i',{staticClass:"fa fa-usd left",attrs:{"aria-hidden":"true"}}):(a.activity_type === 'SMR')?_c('i',{staticClass:"fa fa-envelope-o left",attrs:{"aria-hidden":"true"}}):(a.sort_type === _vm.activitySortTypes.email)?_c('i',{staticClass:"fa fa-envelope-o left",attrs:{"aria-hidden":"true"}}):(a.sort_type === _vm.activitySortTypes.external)?_c('i',{staticClass:"fa fa-external-link left",attrs:{"aria-hidden":"true"}}):(a.sort_type === _vm.activitySortTypes.assignment)?_c('i',{staticClass:"fa fa-user left",attrs:{"aria-hidden":"true"}}):(a.sort_type === _vm.activitySortTypes.status_change)?_c('i',{staticClass:"fa fa-pencil-square-o left",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"left"}),(a.activity_type === 'ER' || a.activity_type === 'UR')?_c('activity-list-email',{ref:`activity-${a.activity_id}`,refInFor:true,attrs:{"index":index,"activity":a,"email":a.email,"cardId":_vm.cardId,"estimateId":_vm.estimateId},on:{"loadActivity":_vm.loadActivity,"deleteActivity":_vm.deleteActivity,"openMenuForEmailAttachments":_vm.openMenuForEmailAttachments}}):(a.subject_type=='T')?_c('activity-list-task',{ref:`activity-${a.activity_id}`,refInFor:true,attrs:{"task":a.task,"assigned-to-list":_vm.filteredListAssignedTo,"activity":a,"viewed":a.viewed},on:{"save":_vm.saveTask,"close":_vm.closeTask,"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"delete":_vm.deleteTask}}):(
                          a.subject_type=='C' &&
                          (a.action_code == 'IPP'
                          || a.action_code == 'ICC'
                          || a.action_code == 'IEC'
                          || a.action_code == 'IFP'
                          || a.action_code == 'IEP'
                          || a.action_code == 'ICP'
                          || a.action_code == 'ExcessInvoiceFullPayment'
                          || a.action_code == 'ExcessInvoicePayment'
                          || a.action_code == 'CompleteInvoiceFullPayment'
                          || a.action_code == 'CompleteInvoicePayment'))?_c('activity-list-invoice',{ref:`activity-${a.activity_id}`,refInFor:true,attrs:{"task":a.task,"assigned-to-list":_vm.filteredListAssignedTo,"activity":a,"viewed":a.viewed},on:{"save":_vm.saveTask,"close":_vm.closeTask,"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"deleteActivity":_vm.deleteActivity}}):((a.action_code == 'AudanetNewClaim'
                          || a.action_code == 'AudanetRepairsAuthorized'
                          || a.action_code == 'AudanetRepairsCancelled'
                          || a.action_code == 'AudanetExcessUpdate'
                          || a.action_code == 'AudanetQuoteSent'))?_c('activity-list-audanet',{attrs:{"index":index,"viewed":a.viewed,"activity":a},on:{"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"deleteActivity":_vm.deleteActivity}}):((a.action_code == 'EstimageOnlineNewRfq'
                          || a.action_code == 'EstimageOnlineRepairsAuthorized'
                          || a.action_code == 'EstimageOnlineRepairsCancelled'
                          || a.action_code == 'EstimageOnlineRegoUpdated'
                          || a.action_code == 'EstimageOnlineExcessUpdate'
                          || a.action_code == 'EstimageOnlineInvoiceExported'
                          || a.action_code == 'EstimageOnlineQuoteSent'))?_c('activity-list-e-i-o',{attrs:{"index":index,"viewed":a.viewed,"activity":a},on:{"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"deleteActivity":_vm.deleteActivity}}):((a.action_code == 'IntegrationIagEstimateSent'
                          || a.action_code == 'IntegrationIagInvoiceSent'
                          || a.action_code == 'IntegrationIagQuoteReceived'
                          || a.action_code == 'IntegrationIagChangedExcess'
                          || a.action_code == 'IntegrationIagJobCancelledReceived'
                          || a.action_code == 'IntegrationIagPaymentDeclinedReceived'))?_c('activity-list-o-r-m',{ref:`activity-${a.activity_id}`,refInFor:true,attrs:{"index":index,"viewed":a.viewed,"activity":a},on:{"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"deleteActivity":_vm.deleteActivity}}):_c('activity-list-default',{ref:`activity-${a.activity_id}`,refInFor:true,attrs:{"index":index,"viewed":a.viewed,"activity":a},on:{"unread":_vm.markAsUnReadActivity,"read":_vm.markAsReadActivity,"deleteActivity":_vm.deleteActivity}})],1)])}),_c('vue-context',{ref:"rightClickMenuForEmailAttachments",scopedSlots:_vm._u([{key:"default",fn:function(item){return _c('ul',{},[(item && item.data && item.data.type=='image')?_c('li',{on:{"click":function($event){return _vm.menuForEmailAttachments($event.target, {type:11, item:item.data})}}},[_vm._v("Add to File/Images ")]):_vm._e(),(item && item.data && item.data.type!='image')?_c('li',{on:{"click":function($event){return _vm.menuForEmailAttachments($event.target, {type:10, item:item.data})}}},[_vm._v("Add to File/Docs ")]):_vm._e(),(item && item.data && item.data.type!='image')?_c('li',{on:{"click":function($event){return _vm.menuForEmailAttachments($event.target, {type:20, item:item.data})}}},[_vm._v("Add to Estimate/Docs ")]):_vm._e(),(item && item.data && item.data.type=='image')?_c('li',{on:{"click":function($event){return _vm.menuForEmailAttachments($event.target, {type:21, item:item.data})}}},[_vm._v("Add to Estimate/Images ")]):_vm._e(),_c('li',{on:{"click":function($event){return _vm.menuForEmailAttachments($event.target, {type:30, item:item.data})}}},[_vm._v("Delete")])])}}])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }