<script>
    import { mapGetters } from 'vuex';
    import {VStepper} from 'vue-stepper-component';
    import GoodWizard from '../../utility/vueGoodWizard';
    import Axios from 'axios';
    import ImageSelector from '../../utility/image-selector';
    import AdvancedSearch from '../../utility/advanced-search';
    import _ from "lodash";

    export default {
        name: 'estimate-options-send-to-claimlinq',
        data: function () {
            return {
                isNewClaim: null,
                isCanSend: true,
                estimate: {
                    vehicleRego: '',
                    vehicleOwner: '',
                    number: '',
                    isAdjusted: null,
                    supplement_number: '',
                },
                currentClaim: {
                    number: '',
                    id: 0,
                    ownerName: '',
                    make: '',
                    model: '',
                    rego: '',
                    claimManagement: '',
                },
                claim: {
                    number: '',
                    id: 0,
                    ownerName: '',
                    make: '',
                    model: '',
                    rego: '',
                    claimManagement: '',
                },
                claims: [],
                images: [],
                files: [],
                allFilesSelected: false,
                allEstimateFilesSelected: false,
                selectedImages: [],
                steps: [
                    {
                        label: 'Confirm Claim',
                        slot: 'estimate-options-send-to-claimlinq-step-1',
                    },
                    {
                        label: 'Select Images',
                        slot: 'estimate-options-send-to-claimlinq-step-2',
                    },
                    {
                        label: 'Select Files',
                        slot: 'estimate-options-send-to-claimlinq-step-3',
                        options: {
                            nextDisabled: true,
                        },
                    }
                ],
                step: 1,
                estimatePdfFiles: [
                    {
                        type: 'original',
                        show: true,
                        name: `Estimate [EstimateNumber].pdf`,
                        attached: false,
                        messageAttached: "Original estimate to be attached",
                        messageNotAttached: "Original estimate not attached",
                    },
                    {
                        type: 'adjusted',
                        show: true,
                        name: `Estimate [EstimateNumber] - ADJ.pdf`,
                        attached: false,
                        messageAttached: "Adjusted estimate to be attached",
                        messageNotAttached: "Adjusted estimate not attached",
                    }
                ],
                notices: {
                  isItemPartWithBlankName: false,
                  isPartWithBlankQty: false,
                }
            };
        },
        watch: {
            'estimate.isAdjusted': function (nv) {
                nv = !!nv;
                this.estimatePdfFiles.forEach(fl => {
                    if (fl.type == 'adjusted') {
                        console.log('Adjusted found', fl);
                        fl.show = nv;
                        if (!nv) {
                            fl.attached = false;
                        }
                    }
                });
            }
        },
        methods: {
            setForNewClaim() {
                this.claim.id = 0;
                this.claim.number = '';
                this.claim.ownerName = '';
                this.claim.make = '';
                this.claim.model = '';
                this.claim.rego = '';
                this.claim.claimManagement = '';
            },
            onClaimSelect: function (c) {
                console.log('selected', c);
                this.claim.id = c.id;
                this.claim.number = c.number;
                this.claim.ownerName = c.ownerName;
                this.claim.make = c.make;
                this.claim.model = c.model;
                this.claim.rego = c.rego;
                this.claim.claimManagement = c.claimManagement;
            },
            toggleSelectAllFiles() {
                if (this.allFilesSelected) {
                    this.files.forEach(file => file.selected = false);
                } else {
                    this.files.forEach(file => file.selected = true);
                }
            },
            toggleSelectAllEstimateFiles() {
                if (this.allEstimateFilesSelected) {
                    this.estimatePdfFiles.forEach(file => file.attached = false);
                } else {
                    this.estimatePdfFiles.forEach((file) => {
                        if ((this.estimate.isAdjusted && file.type == 'adjusted') || file.type == 'original') {
                            file.attached = true;
                        } else {
                            file.attached = false;
                        }
                    });
                }
            },
          getImages(v) {
            let r = [];
            _.forEach(_.cloneDeep(v), (itm) => {
              if (!_.includes(itm.src, 'at=')) {
                itm.src += '/?at=' + localStorage.getItem('token')
              }
              if (!_.includes(itm.previewW220, 'at=')) {
                itm.previewW220 += '/?at=' + localStorage.getItem('token')
              }
              r.push(itm)
            })
            return r
          },
            loadSendToClaimlinq: function (estimate_id) {
                NProgress.start();
                Axios.get(`/fe/claimlinq/${estimate_id}`)
                    .then(response => {
                        this.claims = response.data.claims;
                        this.currentClaim = response.data.currentClaim;
                        if (this.currentClaim && this.currentClaim.id > 0) {
                            this.onClaimSelect(this.currentClaim);
                        }
                        this.images = this.getImages(response.data.images);
                        this.images = this.images.map(image => {
                            this.$set(image, 'selected', false);
                            return image;
                        });
                        this.files = _.orderBy(response.data.files, ['sortDate'], ['desc']);
                        this.files = this.files.map(file => {
                            this.$set(file, 'selected', false);
                            return file;
                        });
                        if (response.data.estimate) {
                            this.estimate = response.data.estimate;
                        }
                        this.notices = response.data.notices;
                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading estimate info");
                        console.log(error);

                    })
                    .finally(e => {
                        NProgress.done();
                    });
            },
            clickCancel: function () {
                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                return;
            },
            goToEstimate() {
              this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
            },
            sendToClaimLinq: function () {
                if (this.isNewClaim) {
                    this.setForNewClaim();
                }
                let estimate_id = this.$route.params.estimate_id;
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/claimlinq/${estimate_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({
                        images: this.selectedImages,
                        files: this.selectedFiles,
                        claim: this.claim,
                    })
                })
                    .then(response => {
                        console.info(response);
                        if (response.status == 200) {
                            if (response.data.status === false) {
                                toastr.error(response.data.msg);
                            } else {
                                toastr.success("Successfully sent to ClaimLinq. ClaimLinq Nbr is #" + response.data.claim_linq_id);
                            }
                            // this.$refs.supplierModal.hide();
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            clickSend: function () {
                if (!this.isCanSend) {
                  return;
                }

                this.isCanSend = false;

                let estimate_id = this.$route.params.estimate_id;
                let pdfs = [];

                this.estimatePdfFiles.forEach(pdf => {
                    if (pdf.attached) {
                        pdfs.push(pdf.type);
                    }
                });

                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/claimlinq/${estimate_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({
                        images: this.selectedImages,
                        files: this.selectedFiles,
                        claim: this.claim,
                        pdfs: pdfs,
                    })
                })
                    .then(response => {
                        if (response.status == 200) {
                            if (response.data.status === false) {
                                toastr.error(response.data.msg);
                            } else {
                                toastr.success(response.data.msg);
                                this.$router.push({name: 'EstimatesEdit', params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab}})
                            }
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(e => {
                        this.isCanSend = true;
                        NProgress.done();
                    });
            },
            onImageSelect: function (imgs) {
                // console.log(imgs)
                this.selectedImages = imgs;
            }
        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
            }),
            errorText() {
              if (!this.notices) {
                return '';
              } else if (this.notices.isItemPartWithBlankName && this.notices.isPartWithBlankQty) {
                return 'text and QTY';
              } else if (this.notices.isItemPartWithBlankName && !this.notices.isPartWithBlankQty) {
                return 'text';
              } else if (!this.notices.isItemPartWithBlankName && this.notices.isPartWithBlankQty) {
                return 'QTY';
              }
            },
            computedEstimateId() {
              if (this.estimate.supplement_number > 0) {
                return this.estimate.parent_estimate_id;
              }
              return  this.$route.params.estimate_id
            },
            isAllFilesSelected() {
                if (this.selectedFiles.length > 0 && this.selectedFiles.length === this.files.length) {
                    this.allFilesSelected = true;
                    return true;
                } else {
                    this.allFilesSelected = false;
                    return false;
                }
            },
            selectedFiles() {
                try {
                    let arr = _.filter(this.files, {'selected': true});
                    let res = [];
                    _.forEach(arr, function (i) {
                        res.push(i.id);
                    });
                    return res;
                } catch (e) {
                    return [];
                }
            },
        },
        mounted: function () {
            this.loadSendToClaimlinq(this.$route.params.estimate_id);
        },
        components: {
            VStepper,
            GoodWizard,
            ImageSelector,
            AdvancedSearch,
        },
    };

</script>

<template>
    <div class="estimate-options-send-to-claimlinq">
        <div class="page-header">
            <h4>Edit Estimate - {{estimate.number}} <span v-if="estimate.supplement_number > 0">- {{estimate.supplement_number}} </span><span v-if="estimate.vehicleOwner">| {{estimate.vehicleOwner}} </span><span v-if="estimate.vehicleRego">| {{estimate.vehicleRego}}</span></h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link :to="'/'">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/estimates'">View Estimates</router-link>
                </li>
                <li class="breadcrumb-item">
                    <a href="" @click.prevent="goToEstimate">Edit Estimate</a>
                </li>
                <li class="breadcrumb-item">Options</li>
                <li class="breadcrumb-item active">Send To Claimlinq</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="">

            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="estimateOptionsSendToPartsSearch">
                <div slot="estimate-options-send-to-claimlinq-step-1" class="estimate-options-send-to-claimlinq-step-1">
                    <div class="header">
                        Confirm Claim
                    </div>
                    <div v-if="(currentClaim && currentClaim.id > 0)" class="subheader">
                        Update estimate for the claim reference number in Claimlinq.
                    </div>
                    <div v-else class="subheader">
                        Create a new or enter an existing claimlinq reference number to send to.
                    </div>
                  <div v-if="notices && (notices.isItemPartWithBlankName || notices.isPartWithBlankQty)" class="subheader" style="color:red;">
                    The system has detected that you have a line item with a blank <span>{{errorText}}</span>. Review before continuing or continue and the line will be removed automatically.
                  </div>
                    <div>
                        <template>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div v-if="!(currentClaim && currentClaim.id > 0)" class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label">Send as a new</label>
                                        <div class="col-sm-7 col-7 d-flex">
                                            <label class="form-check-inline radio" style="width:70px;">
                                                <input class="form-check-input" type="radio"
                                                       @click="setForNewClaim"
                                                       v-model="isNewClaim"
                                                       :value="true">
                                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                                            </label>
                                            <label class="form-check-inline radio">
                                                <input class="form-check-input" type="radio"
                                                       v-model="isNewClaim"
                                                       :value="false">
                                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Claim Number</label>
                                        <div class="col-sm-7 col-7">
                                            <input v-if="(currentClaim && currentClaim.id > 0)" v-model="claim.number" class="form-control" disabled="disabled">
                                            <advanced-search v-else
                                                             ref="claim_number"
                                                             v-model="claim.number"
                                                             :searchData="claims"
                                                             :searchKey="'number'"
                                                             :searchLabel="'Claim Number'"
                                                             :isShowNew="false"
                                                             @onSelect="onClaimSelect"
                                                             :isDisabled="isNewClaim !== false"
                                            >
                                            </advanced-search>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="margin-top: 30px;">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-12 col-12 col-form-label" :class="{'grey-color':(isNewClaim !== false)}" style="font-weight: bold">Claim information</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Vehicle Owner</label>
                                        <div class="col-sm-7 col-7">
                                            <input type="text" class="form-control" v-model="claim.ownerName" disabled="disabled">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Rego</label>
                                        <div class="col-sm-7 col-7">
                                            <input type="text" class="form-control" v-model="claim.rego" disabled="disabled">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Make</label>
                                        <div class="col-sm-7 col-7">
                                            <input type="text" class="form-control" v-model="claim.make" disabled="disabled">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Model</label>
                                        <div class="col-sm-7 col-7">
                                            <input type="text" class="form-control" v-model="claim.model" disabled="disabled">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-block bg-white">
                                    <div class="form-group row">
                                        <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':(isNewClaim !== false)}">Claim Management</label>
                                        <div class="col-sm-7 col-7">
                                            <input type="text" class="form-control" v-model="claim.claimManagement" disabled="disabled">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div slot="estimate-options-send-to-claimlinq-step-2" class="estimate-options-send-to-claimlinq-step-2">
                    <div class="header">
                        Select Images
                    </div>
                    <div class="subheader">
                        Choose the images to attach by ticking the box next to the image
                    </div>
                    <div class="box box-block">
                        <div class="row send-to-additional-position-image-wizard">
                            <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>
                        </div>
                    </div>
                </div>
                <div slot="estimate-options-send-to-claimlinq-step-3" class="estimate-options-send-to-claimlinq-step-3">
                    <div class="header">
                        Select Files
                    </div>
                  <div class="subheader">
                    Choose to add a copy of the Estimate PDF
                  </div>
                  <div class="box box-block">
                    <div class="row">
                      <table class="table b-table select-parts">
                        <thead class="bg-navyblue">
                        <tr>
                          <th class="header-check">
                            <label class="form-check-inline checkbox">
                              <input @click="toggleSelectAllEstimateFiles" class="form-check-input" type="checkbox" v-model="allEstimateFilesSelected">
                              <span class="icon"><i class='bx bx-check'></i></span>
                            </label>
                          </th>
                          <th class="header-name" style="text-align: left;">Select All</th>
                          <th class="header-name"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="file in estimatePdfFiles">
                          <tr v-if="file.show">
                            <td>
                              <label class="form-check-inline checkbox">
                                <input class="form-check-input" type="checkbox" v-model="file.attached">
                                <span class="icon"><i class='bx bx-check'></i></span>
                              </label>
                            </td>
                            <td>{{ file.name.replace('[EstimateNumber]', estimate.number) }}</td>
                            <td>
                              <strong>Status:</strong>
                              <span v-show="!file.attached" class="text-muted">{{ file.messageNotAttached }}</span>
                              <strong v-show="file.attached">{{ file.messageAttached }}</strong>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>

                    </div>
                  </div>
                    <div class="subheader">
                        Choose the files to attach by ticking the box next to the file
                    </div>
                    <div class="box box-block">
                        <div class="row">
                            <table class="table b-table select-parts">
                                <thead class="bg-navyblue">
                                <tr>
                                    <th class="header-check">
                                        <label class="form-check-inline checkbox">
                                            <input @click="toggleSelectAllFiles" class="form-check-input" type="checkbox" v-model="allFilesSelected">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                    </th>
                                    <th class="header-name" style="text-align: left;">Select All</th>
                                    <th class="header-name"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="file in files">
                                    <td>
                                        <label class="form-check-inline checkbox">
                                            <input class="form-check-input" type="checkbox" v-model="file.selected">
                                            <span class="icon"><i class='bx bx-check'></i></span>
                                        </label>
                                    </td>
                                    <td>{{ file.name }}</td>
                                    <td>
                                        <strong>Status:</strong>
                                        <span v-show="!file.selected" class="text-muted">Not attached</span>
                                        <strong v-show="file.selected">To be attached</strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </good-wizard>

            <div class="wizard__buttons">
                <div class=" pull-right">
                    <button
                        class="btn btn-outline-primary pull-left button-cancel mr-0"
                        type="button"
                        @click="clickCancel"
                    >
                        Cancel
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 0"
                            class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
                            type="button"
                            @click="$refs.estimateOptionsSendToPartsSearch.goBack()"
                    >
                        Back
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 2"
                            class="btn btn-primary pull-right button-next"
                            type="button"
                            @click="$refs.estimateOptionsSendToPartsSearch.goNext()"
                            :tabindex="4"
                            :disabled="(isNewClaim === null) && !(Number(currentClaim.id)>0)"
                    >
                        Next
                    </button>
                    <button
                            v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep == 2"
                            class="btn btn-primary pull-right button-card-estimate"
                            type="button"
                            @click="clickSend"
                            :disabled="!isCanSend"
                            style=""
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .V3 .wizard .wizard__body table.b-table tr th,
    .V3 .wizard .wizard__body table.b-table tr td {
        padding: 13px 15px;
    }

    .grey-color {
        opacity: 0.6;
    }

    .V3 .estimate-options-send-to-claimlinq .header-check {
        padding: 0.75rem;
        width: 5%;
        min-width: 45px;
    }

    /* The original select box is having transparent bg, this is an adjustment when placed on table header */
    .V3 .estimate-options-send-to-partssearch .header-check .form-check-inline {
        background-color: #FFFFFF;
        border-radius: 3px;
    }
</style>
<style>
    .V3 .estimate-options-send-to-claimlinq .wizard__body {
        position: relative;
        width: 100%;
        margin: 0px auto 0 auto;
        padding: 30px 15px 15px 15px;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__steps {
        width: 100%;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-1 {
        margin: 0 auto;
        min-width: 573px;
        width: 573px;
        margin: 0px auto 0 auto;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-1 .table th {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        /*   vertical-align: top; */
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-1 .table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-2 {
        width: 100%;
        min-width: 100%;
        margin: 0px auto 0 auto;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-3 {
        min-width: 900px;
        width: 900px;
        margin: 0px auto 0 auto;
    }

    .V3 .estimate-options-send-to-claimlinq .wizard__buttons {
        max-width: 573px;
        margin: 0 auto 70px auto;
        /*padding-right: 15px;*/
    }

</style>
<style scoped>
    .V3 .select-suplier-show-more {
        display: inline-table;
        position: relative;
        transform: translateY(10px);
        margin-bottom: -25px;
        margin-left: 9px;
    }

    .V3 .select-suplier-show-more a {
        display: inline-block;
        color: #1C1F39;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .V3 .select-suplier-show-more a i {
        color: #1C1F39;
        font-size: 11px;
        margin-left: 3px;
    }

    .V3 .select-suplier-show-more p {
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
    }

    .V3 .send-email-type {
        display: inline-table;
        position: relative;
        transform: translateY(10px);
        margin-bottom: -25px;
        margin-left: 9px;
    }

    .V3 .send-email-type p {
        margin-bottom: 0.7rem;
    }

    .V3 .send-email-type label.checkbox > span.text {
        display: inline-block;
        margin-top: 5px;
        padding-right: 15px;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
    }

    .V3 .estimate-options-send-to-claimlinq >>> .card-block {
        padding: 0px;
    }

    .V3 .estimate-options-send-to-claimlinq .card-block {
        padding: 0px;
    }

    .V3 .wizard__buttons .pull-left {
        float: left !important;
        margin-right: 10px;
    }

    .V3 .wizard__buttons .pull-right {
        float: right !important;
        margin-left: 10px;
    }

    .V3 .wizard__buttons .btn {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        height: 38px;
    }

    .V3 .wizard__buttons .button-cancel {
        padding: 10px 21px;
        background: #FFFFFF;
        border: 1px solid rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-card {
        padding: 10px 15px;
        background: #FFFFFF;
        color: #5E79FF;
    }

    .V3 .wizard__buttons .button-card-estimate {
        padding: 10px 15px;
    }

    .V3 .wizard__buttons .button-next {
        padding: 10px 15px;
        width: 83px;
    }

    .V3 .wizard__buttons .button-back {
        padding: 10px 26px;
        background: #FFFFFF;
        border-color: #5E79FF;
        width: 83px;
    }

    @media (max-width: 823px) AND (min-width: 604px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }

        .V3 .compact-sidebar .card-new .wizard__buttons {
            width: 573px;
            margin: 0 auto;
            min-width: 573px;
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    @media (max-width: 603px) {
        .V3 .card-new .wizard__buttons {
            padding-left: 15px;
            padding-right: 15px;
            width: auto;
            min-width: auto;
        }
    }

    @media (max-width: 512px) {
        .V3 .card-new .wizard__buttons button {
            margin-bottom: 10px;
            float: none !important;
            margin-left: 0px;
            margin-right: 0px;
            display: block;
        }
    }

    @media screen and (max-width: 575px) {
        .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-1,
        .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-2,
        .V3 .estimate-options-send-to-claimlinq .wizard__body .estimate-options-send-to-claimlinq-step-3 {
            width: 100%;
            min-width: 100%;
        }
        .V3 .estimate-options-send-to-claimlinq .wizard__buttons {
            max-width: 100%;
            padding-right: 30px;
        }
    }

</style>

<style scoped>
    .V3 .estimate-options-send-to-claimlinq .form-control:disabled {
        opacity: 1;
    }

    .V3 .estimate-options-send-to-claimlinq .estimate-options-send-to-claimlinq-step-1 .supplier-due-by-time select.form-control {
        padding-right: 20px !important;
        color: #1C1F39;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
    }

    .V3 .estimate-options-send-to-claimlinq .estimate-options-send-to-claimlinq-step-1 .supplier-due-by {
        width: 100px;
        margin: 15px auto 0 auto;
        text-align: left;
        color: rgba(28, 31, 57, 0.5);
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
    }

    .V3 .estimate-options-send-to-claimlinq .estimate-options-send-to-claimlinq-step-1 .select-parts .select-parts-check-all span.icon,
    .V3 .estimate-options-send-to-claimlinq .estimate-options-send-to-claimlinq-step-1 .select-parts span.icon {
        margin-right: 0px;
    }

    @media screen and (max-width: 500px) {
        .V3 .page-header {
            min-height: 100px !important;
        }
        .page-header h4 {
            height: auto;
        }
    }
</style>

<style>
    .V3 .estimate-options-send-to-claimlinq .card-block {
        padding: 0px;
    }
</style>
