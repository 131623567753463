<template>
  <form class="search-filter">
    <div class="search-filter__box" :class="{'search-filter__box__focused': isInputInFocus}" :style="boxStyle">
      <div class="search-filter__scroll-container">
        <ul class="search-filter__ul">
          <li class="search-filter__li" style="z-index: 999999">
            <!-- Token -->
            <div class="token font-12 font-12--light">
              <template v-for="(level, index) in token">
                <div class="token__level" :key="`${level}-${index}`" v-if="!level.resultObjectOnly">
                  {{ level.label }}
                </div>
              </template>
              <div class="token__remove" @click="remove" v-if="tokenComplete"><i class='bx bx-x'></i></div>
            </div>
          </li>

          {{ /* sample to check styling */ }}
          <!--
          <li class="search-filter__li" v-if="tokens">
              <div class="token font-12 font-12--light">
                  <div class="token__level">Date</div>
                  <div class="token__container">
                      <div class="token__value" data-id="@fanny">
                          File Open
                      </div>
                      <div class="token__remove" @click="remove"><i class='bx bx-x'></i></div>
                  </div>
              </div>
          </li>
          -->

          <!-- input with drop down-->
          <li class="search-filter__li search-filter__li--input">
            <div class="dropdown d-flex" style="width: 100%;">
              <date-range-picker
                  v-show="showDateRange"
                  ref="picker"
                  opens="left"
                  class="font-12"
                  :ranges="predefinedTimeRanges"
                  :locale-data="localeData"
                  :close-on-esc="true"
                  @update="handleDateUpdate"
                  @toggle="handleDateToggle"
                  v-model="dateRange"
              />
              <b-form-input v-show="!showDateRange"
                            class="search-filter__input font-12"
                            ref="search-filter-input"
                            id="search-filter-input"
                            v-model="search"
                            type="text"
                            @focus="focusInput"
                            @click="showDropdown"
                            @keydown="handleKeydown"
                            @blur="isInputInFocus = false"
                            @keyup="handleKeyup"
                            debounce="500"
                            aria-haspopup="true"
                            aria-expanded="false"
                            autocomplete="off"
                            data-toggle="dropdown"
                            style="width: 100%; z-index: 999999"
                            v-closable="{
                              exclude: ['dropdown', 'searchIcon',],
                              handler: 'hideDropdown'
                            }"
                            :placeholder="computedPlaceholder"
              >

              </b-form-input>

              <div ref="dropdown"
                   class="dropdown-menu search-filter__dropdown-menu"
                   aria-labelledby="search-filter-input"
                   v-if="!isDateFilter && filteredFilters.length && !tokenComplete && !(token[0] && token[0].id === 'dateRange')">
                <template v-for="(filter, index) in filteredFilters">
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     :key="filter.label.toLowerCase()"
                     @click.prevent.stop="selectFilter(index)">
                    <i class='bx' :class="filter.iconClass"></i>
                    <span>{{ filter.label }}</span>
                  </a>
                </template>
              </div>

              <div ref="dropdown"
                   class="dropdown-menu search-filter__dropdown-menu"
                   style="width: 215px"
                   aria-labelledby="search-filter-input"
                   v-if="!isDateFilter && filteredFilters.length && !tokenComplete && token[0] && token[0].id === 'dateRange'">
                <template>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     style="display: block"
                     @click.prevent.stop="selectFilter(0)">
                    <span>This month </span><span style="float: right">{{ computedCurrentMonth }}</span>
                  </a>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     style="display: block"
                     @click.prevent.stop="selectFilter(1)">
                    <span>Last month </span><span style="float: right">{{ computedLastMonth }}</span>
                  </a>
                  <a class="dropdown-item search-filter__dropdown-item font-12"
                     ref="filters"
                     @focus="showDropdown"
                     @keydown="handleKeydownDropdown"
                     href="#"
                     @click.prevent.stop="selectFilter(2)">
                    <span>Custom Range</span>
                  </a>
                </template>
              </div>


                <i  ref="searchIcon" @click="clickOnSearchIcon" @focus="clickOnSearchIcon" class="bx bx-search" style="font-size: 24px; z-index: 99999999999; margin-top: 5px; color: #9395a1"></i>

            </div>
          </li>
        </ul>
      </div>
      <div ref="overlay" class="overlay" @click="hideDropdown"></div>
    </div>
    <div class="rs-button-container"></div>

    <div v-if="debug" class="debug">
      <div class="debug__title">Search-Filter Debug:</div>
      <div class="debug__item"><span class="debug__key">tokenComplete:</span><span class="debug__value">{{ tokenComplete }}</span></div>
      <div class="debug__item"><span class="debug__key">dropdownPosition:</span><span class="debug__value">{{ dropdownPosition }}</span></div>
      <div class="debug__item"><span class="debug__key">filterResult:</span><span class="debug__value">{{filterResult || 'Empty'}}</span></div>
      <div class="debug__item"><span class="debug__key">filterResultObj:</span><span class="debug__value">{{filterResultObj || 'Empty'}}</span></div>
    </div>
  </form>
</template>

<script>
import Filter from './Filter';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import _ from 'lodash';
import Vue from 'vue'
// This variable will hold the reference to
// document's click handler
let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl?.contains(e.target)
        }
      })
      // console.log(e.target)
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

export { Filter }

export default {
  props: {
    tokenCopy: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'empty' // | 'cards' | 'activities' | --take a look in Filter.vue file for other options
    },
    value: {
      type: String || Object,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search or Filter results'
    },
    height: {
      type: String,
      default: '39px'
    },
    filters: {
      type: Array,
      default: () => []
    },
    debug: {
      type: Boolean,
      default: false,
    },
    filterValue: {
      type: String,
      default: '',
    },
    },
  components: {
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)


    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 6);
    let last14Days = new Date();
    last14Days.setDate(last14Days.getDate() - 13);


    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);

    let date = new Date();
    date.setHours(0, 0, 0, 0);
    const thisMonthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const thisMonthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
      dateRange: {startDate, endDate},
      tokens: true,
      selectedCategory: -1,
      token: [],
      search: '',
      dropdownPosition: 0,
      dropdown: {},
      isInputInFocus: false,
      localeData: {
        format: 'ddd, mmm dd, yyyy'
      },
      predefinedTimeRanges: {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'Last 7 Days': [last7Days, startDate],
        'Last 14 Days': [last14Days, startDate],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Custom Range': [today, today],
      },
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      showDateRange: false,
    };
  },
  computed: {
    computedCurrentMonth() {
      return this.months[this.currentMonth] + ' ' + new Date().getFullYear();
    },
    computedLastMonth() {
      if (this.currentMonth === 0) {
        return 'December' + ' ' + (new Date().getFullYear() - 1);
      } else {
        let month = this.months[this.currentMonth - 1];
        return month + ' ' + new Date().getFullYear();
      }
    },
    currentMonth() {
      return new Date().getMonth();
    },
    computedPlaceholder() {
      return this.token.length ? '' : this.placeholder;
    },
    currentLevel() {
      return this.token.length - 1; // minus category select
    },
    maxLength() {
      if (this.selectedCategory >= 0) {
        // console.log('this.mappedFilters[this.selectedCategory]', this.mappedFilters[this.selectedCategory]);
        return this.mappedFilters[this.selectedCategory].options.length;
      } else {
        return null;
      }
    },
    // add original index for search filter feature
    mappedFilters() {
      return this.filters.map((filter, index) => {
        filter.originalIndex = index;
        return filter;
      })
    },
    filteredFilters() {
      // first select filter category
      if (this.token.length === 0) {
        // console.log('filtered filter -- CATEGORY')
        return this.mappedFilters.filter(filter => {
          const label = filter.label.toLowerCase();
          const searchWord = this.search.trim().toLowerCase();
          return label.includes(searchWord);
        });
      } else if (this.token.length > 0 && this.mappedFilters[this.selectedCategory].options[this.currentLevel]) {
        // console.log('filtered filter -- OPTIONS')
        return this.mappedFilters[this.selectedCategory].options[this.currentLevel].filter(filter => {
          if (filter.label !== null) {
            const label = filter.label.toLowerCase();
            const searchWord = this.search.trim().toLowerCase();
            return label.includes(searchWord);
          }
        });
      } else {
        return this.mappedFilters;
      }
    },
    isDateFilter() {
      return false;
      // return this.filteredFilters[0] && this.filteredFilters[0].type && this.filteredFilters[0].type === 'Date';
    },
    tokenComplete() {
      return this.currentLevel >= this.maxLength;
    },
    filterResultObj() {
      let resObj = {
        // include manual input from text input
        search: this.search
      };
      if (this.token.length > 0) {
        this.token.forEach((token, index) => {
          const id = token.id
          // category always even or 0 -- due to nature of array
          if (index === 0 || index % 2 === 0) {
            resObj[id] = ''

            // value always odd
          } else if (token.type === 'Date') {
            resObj[this.token[index - 1].id] = token;
          } else {
            resObj[this.token[index - 1].id] = token.label;
          }
        })
      }
      return resObj;
    },
    filterResult() {
      let res = '';
      if (this.token.length === 0) {
        res = '';
      } else if (this.token.length > 0) {
        this.token.forEach((token, index) => {
          // only output string
          if (token.resultObjectOnly || (index > 0 && this.token[index - 1] && this.token[index - 1].resultObjectOnly)) return;

          // ignore first token, it is category for example, 'Rego', 'Vehicle Owner'
          if (index === 0) {
            res = '';
          }
          else if (index === 1) {
            res = token.label ? token.label : res;
          }
          else if (index > 1) {
            res = token.label ? res.trim() + ' ' + token.label : res;
          }
        });
      }
      res += ' ' + this.search;
      res = res.trim();
      // console.log('token.length', this.token.length, res);
      return res;
    },
    boxStyle() {
      return {
        'height': this.height
      }
    }
  },
  watch: {
    filterValue(val) {
      console.log('filterValue', val)
      this.search = val;
    },
    filterResult(val) {
      this.$emit('input', val);
    },
    filterResultObj(valObj) {
      this.$emit('change', valObj)
    }
  },
  mounted() {
    this.dropdown = $(this.$refs['search-filter-input']);

    // additional external listener
    // these element are not covered by overlay

    if (document.querySelector('.site-header')) {
      document.querySelector('.site-header').addEventListener('click', this.hideDropdown)
    }

    if (document.querySelector('.page-header')) {
      document.querySelector('.page-header').addEventListener('click', this.hideDropdown)
    }

    if (this.filterValue) {
      this.search = this.filterValue;
    }

    if (!_.isEmpty(this.tokenCopy)) {
      this.token = this.tokenCopy;
      this.selectedCategory = this.tokenCopy[0].originalIndex;
    }

    // this.mappedFilters = Filter.generate(this.type);
  },
  unmounted() {
    document.querySelector('.site-header').removeEventListener('click', this.hideDropdown);
    document.querySelector('.page-header').removeEventListener('click', this.hideDropdown);
  },
  filters: {
    formatDate(val) {
      return moment(val).format('ddd, MMM DD, YYYY')
    }
  },
  methods: {
    clickOnSearchIcon() {
      this.showDropdown();
      this.$nextTick(() => {
        if (this.$refs["search-filter-input"]) {
          this.$refs["search-filter-input"].focus();
        }
      })
    },
    focusInput() {
      this.isInputInFocus = true;
      this.showDropdown();
    },
    showDropdown() {
      if (this.tokenComplete) return;
      $(this.$refs.dropdown).dropdown().toggle(true);
      $(this.$refs.overlay).show();
    },
    hideDropdown() {
      $(this.$refs.dropdown).dropdown().toggle(false);
      $(this.$refs.overlay).hide();
    },
    remove() {
      this.token = [];
      this.search = '';
      this.selectedCategory = -1;
      this.dropdownPosition = 0;
      this.showDateRange = false;
      this.$emit('updateToken', this.token);
    },
    selectFilter(index) {
      console.log('index', index)
      if (this.token.length === 0) {
        // select category
        this.selectCategory(index)
      } else {
        // do nothing, and continue
        // select a non-category filter will be calculated in filtered filters
      }

      // selecting a date
      if (this.filteredFilters[index].label === 'Date' || this.filteredFilters[index].label === 'Custom Date Range' ) {
        this.showDateRange = true;
        this.$nextTick(() => {
          this.$refs.picker.togglePicker(true);
        });
      }

      // hide dropdown when a token complete
      this.$nextTick(() => {
        // if complete hide dropdown
        if (this.tokenComplete) {
          this.hideDropdown();
        }
      });

      if (this.filteredFilters[index].label !== 'Custom Date Range') {
        this.token.push(this.filteredFilters[index]);
        this.$emit('updateToken', this.token);
      }

      this.resetSearchInput();
    },
    selectCategory(index) {
      this.selectedCategory = this.filteredFilters[index].originalIndex;
    },
    resetSearchInput() {
      this.search = '';
      this.dropdownPosition = 0;
      const searchInput = this.$refs['search-filter-input'];
      this.$nextTick(() => {
        if (searchInput) {
          searchInput.focus();
        }
      })
    },
    // Search Input Keydown
    handleKeydown(e) {
      switch(e.keyCode) {
        case 27:
        {
          this.hideDropdown();
          break;
        }
        case 32: // space
        {
          if (!this.search.trim().length) return;
          // search if match any token label
          const index = this.filteredFilters.findIndex(filter => filter.label.toLowerCase() === this.search.trim().toLowerCase());
          console.log(index);
          if (index > -1) {
            event.preventDefault();
            this.selectFilter(index);
          }
          break;
        }
        case 8: // delete
        {
          if (this.token.length && !this.search.length) {
            let lastFilter = this.token.pop();

            // pop again if the category is resultObjectOnly
            if (this.token[this.token.length-1] && this.token[this.token.length-1].resultObjectOnly) {
              lastFilter = this.token.pop()
            }

            console.log('lastFilter', lastFilter);
            this.$emit('updateToken', this.token);
            // this.search = lastFilter.label;
            this.search = '';
          }

          break;
        }
        case 13: // enter
        {
          e.preventDefault();
        }
          break;
        default: {
          this.showDropdown();
        }
      }
    },
    // Search Input Keyup
    handleKeyup(e) {
      switch(e.keyCode) {
        case 38: // up
        {
          e.preventDefault();
          break;
        }
        case 40: // down
        {
          e.preventDefault();
          this.dropdownPosition = 0;
          this.$refs['filters'][this.dropdownPosition].focus();
          break;
        }
        case 8: // delete
        {
          // when hit delete and everything empty, reset state
          if (!this.token.length && !this.search.length) {
            this.remove();
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    // handle keys on dropdown
    handleKeydownDropdown(e) {
      e.preventDefault();
      switch(e.keyCode) {
        case 38: // up
        {
          this.dropdownPosition --;
          if (this.dropdownPosition > -1) {
            this.$refs['filters'][this.dropdownPosition].focus();
          } else {
            // focus to input
            this.dropdownPosition = 0;
            this.$refs['search-filter-input'].focus();
            this.showDropdown();
          }
          break;
        }
        case 40: // down
        {
          this.dropdownPosition ++;
          if (this.dropdownPosition < this.$refs['filters'].length) {
            console.log('')
          } else {
            this.dropdownPosition = 0;
          }
          this.$refs['filters'][this.dropdownPosition].focus();
          break;
        }
        case 32: // space
        case 13: // enter
        {
          this.selectFilter(this.dropdownPosition);
          this.dropdownPosition = 0;
          break;
        }
        case 8: // delete
        case 27: // esc
        default:
        {
          if (e.key.length === 1) {
            this.search += e.key;
          }
          this.showDropdown();
          this.dropdownPosition = 0;
          this.$refs['search-filter-input'].focus();
        }
      }
    },
    handleDateUpdate() {
      const obj = this.dateRange;
      obj.type = 'Date';
      obj.label = `${moment(this.dateRange.startDate).format('ddd, MMM DD, YYYY')} - ${moment(this.dateRange.endDate).format('ddd, MMM DD, YYYY')}`
      // this.token.pop();

      this.token.push(this.dateRange);
      this.$emit('updateToken', this.token);

      // focus on next input
      this.$nextTick(() => {
        this.resetSearchInput();
      });
    },
    handleDateToggle(show) {
      // console.log('handleDateToggle', arguments);
      // console.log('this.isDateFilter', this.isDateFilter);
      // console.log('this.currentLevel', this.currentLevel);
      if (!show) {
        this.showDateRange = false;
        this.$nextTick(() => {
          // console.log('this.currentLevel', this.currentLevel);

          if (this.token[this.currentLevel].startDate) {
            return;
          } else {
            const lastFilter = this.token.pop();
            this.$emit('updateToken', this.token);
            console.log('lastFilter', lastFilter)
            // this.search = lastFilter.label.slice(0, -1);
            this.search = '';

            this.$nextTick(() => {
              const searchInput = this.$refs['search-filter-input'];
              if (searchInput) {
                searchInput.focus();
              }
            })
          }
        });
      }
    }
  }
}
</script>
<style scoped>
#search-filter-input:focus {
  outline: none !important;
}
ul {
  margin: 0;
  padding: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: normal;
  letter-spacing: 0;
  color: #1C1F39;
}

.search-filter__box {
  display: flex;
  align-items: center;
  position: relative;
  /* background-color: turquoise; */

  box-sizing: border-box;
  padding-left: 5.5px;

  height: 39px;
  border: 2px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;

  z-index: 10;
}

.search-filter__box__focused {
  border: 2px solid rgba(94, 121, 255, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgb(94 121 255 / 25%) !important;
  background-color: #ebf0fc !important;
}
.search-filter__box__focused >>> .search-filter__li--input{
  border-color: #ebf0fc !important;
}

.search-filter__scroll-container {
  width: 100%;
}

.search-filter__ul {
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  /* justify-content: center; */
}

.search-filter__li {
  display: inline-block;
  position: relative;
  margin-right: 5.5px;
}

.search-filter__li:hover {
  /* reset */
  border-bottom-color: transparent !important;
}

.search-filter__li > * {
  display: inline-block;
}

.search-filter__li--input {
  flex: 1 1 100%;
}

.search-filter__input {
  position: relative;
  /* border-color: 0; */
  box-sizing: border-box;
  width: 100%;
  box-shadow: none !important;
  border-color: transparent !important;
  margin: 0;
  height: 16px;
  width: 140px;
  padding: 0;
}
.search-filter__input::placeholder {
  color: #1C1F39;
  opacity: 0.5;
}
.search-filter__input--no-placeholder::placeholder {
  display: none;
}

/* Dropdown */
/* Dropdown Container */
.search-filter__dropdown {

}
/* Dropdown Container */
.dropdown-menu.search-filter__dropdown-menu {
  margin-top: 19px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-color: white;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
  border-radius: 3px;

  max-height: 275px;
  overflow-y: scroll;
}

/* Dropdown Item */
.dropdown-item.search-filter__dropdown-item {
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 2px;
}
.dropdown-item.search-filter__dropdown-item .bx {
  margin-right: 7px;
  font-size: 18px;
}
.dropdown-item.search-filter__dropdown-item span {
  position: relative;
  top: 2px;
}

/* theme */
.V3 .dropdown-item.search-filter__dropdown-item {
  font-size: 12px;
  font-weight: 600;
}
.V3 .dropdown-item.search-filter__dropdown-item:hover,
.V3 .dropdown-item.search-filter__dropdown-item:active,
.V3 .dropdown-item.search-filter__dropdown-item:focus {
  background-color: rgba(94, 121, 255, 0.1);
  color: #5E79FF;
}


.token {
  display: flex;
  justify-content: center;
}
.token > * {
  margin-right: 2px;
  padding: 6px 5px;
  background-color: rgba(22,25,50,0.05);
  border-radius: 2px;
}
.token div:last-child {
  margin-right: 0;
}

.token__value,
.token__category,
.token__level {
  white-space: nowrap;
}

.token__container {
  display: flex;
}
.token__remove .bx {
  vertical-align: middle;
  font-size: 18px;
}
.token__remove:hover {
  cursor: pointer;
}


/* daterangepicker */
::v-deep .vue-daterange-picker i.glyphicon {
  display: none !important;
}

::v-deep .reportrange-text {
  height: auto;
  margin-right: 5px;
  color:  #8E909C;
  font-weight: normal;
  padding: 10px 30px 5px 5px;
  position: relative;
  top: 1px;
  border: 0;
  height: 31px;
}
::v-deep .reportrange-text span {
  /* color: transparent; */
}
::v-deep .daterangepicker {
  margin-top: 20px;
}
::v-deep .calendars {
  display: flex;
  flex-flow: row-reverse;
}

/* daterangepicker re-styled to design */
:v-deep .vue-daterange-picker .dropdown-menu {
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
  border-radius: 3px;
  overflow: hidden;
}

::v-deep div.drp-calendar,
::v-deep div.calendar-table {
  padding: 0 !important;
}

/* calendar head -- month selector */
::v-deep div.calendar-table thead tr th {
  background-color: #00b050 !important;
  border-color: #00b050 !important;
  color: #FFFFFF;
  font-weight: normal;
  min-height: 36px;
}
/* calendar head -- height */
::v-deep div.daterangepicker .calendar-table table tr {
  height: 30px;
}
/* arrow */
::v-deep .daterangepicker .calendar-table .next span,
::v-deep .daterangepicker .calendar-table .prev span {
  border-color: #FFFFFF;
}

/* day names */
::v-deep div.drp-calendar tbody tr:nth-child(1) th {
  color: #00b050;
  font-weight: normal;
}
/* selected days */
::v-deep div.calendar-table {
  border: 0 !important;
}

/* hovered arrow */
::v-deep .daterangepicker td.available:hover,
::v-deep .daterangepicker th.available:hover {
  background-color: #4662ef;
}

::v-deep .daterangepicker td.active,
::v-deep .daterangepicker td.active:hover {
  background-color: #5E79FF !important;
  color: #e9fff3 !important;
}

::v-deep .daterangepicker td.off.in-range {
  /* background-color: #ebf4f8;
  border-color: transparent; */
}

::v-deep .daterangepicker td.in-range {
  background-color: rgba(255,127,46,0.1);
  color: #5E79FF;
  font-weight: bold;
}

::v-deep .daterangepicker td.off.start-date,
::v-deep .daterangepicker td.off.end-date {
  color: #ffffff;
}

::v-deep .daterangepicker td.off.end-date.in-range.active {
  color: #5E79FF;
}

/* in range days */
::v-deep .daterangepicker td.in-range {
  /* background-color: #e5e9ff !important; */
}

/* date box size */
::v-deep .daterangepicker .calendar-table td,
::v-deep .daterangepicker .calendar-table th {
  min-width: 24px;
  width: 24px;
  height: 24px;
  /* padding: 0; */
}

/* predefined */
::v-deep .daterangepicker.show-calendar .ranges {
  margin-top: 0;
}

::v-deep .daterangepicker .ranges ul {
  width: 120px;
}

::v-deep div.daterangepicker div.ranges li {
  color: #1C1F39;
  font-family: "Nunito Sans";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;

  background-color: transparent;
  border-color: rgba(28, 31, 57, 0.1);;
  border-radius: 3px;
  margin: 4.5px;
  margin-bottom: 8px;

  height: 28px;
}

::v-deep .daterangepicker .ranges li:hover {
  background-color: rgba(255,127,46,0.1);
  color: #5E79FF;
}

::v-deep div.daterangepicker div.ranges li.active {
  background-color: #5E79FF;
  color: #e9fff3;
  border-radius: 3px;
}

/* container size */
::v-deep div.daterangepicker.show-ranges {
  min-width: auto;
}

/* buttons */
::v-deep div.daterangepicker .drp-buttons {
  border-top: 0;
  padding-right: 4.5px;
  padding-left: 4.5px;
  display: flex !important;
}
::v-deep div.daterangepicker .drp-buttons .btn {
  margin-left: 6px;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: normal;
  min-width: 52px;
}

::v-deep div.daterangepicker .drp-buttons .applyBtn {
  border-radius: 3px;
  height: 28px;
  background-color: #5E79FF;
}
/* make cancel button to right */
::v-deep div.daterangepicker .drp-buttons .cancelBtn {
  float: right;
}

/* calendar width individual */
::v-deep div.daterangepicker .drp-calendar {
  max-width: 215px;
}

/* date label below calendar */
::v-deep div.daterangepicker .drp-selected {
  background: rgba(27,30,56,0.05);
  border-top: 1px solid rgba(27,30,56,0.1);
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* end daterangepicker re-styled */

.overlay {
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  /* background: aquamarine; */
  opacity: .3;
  top: 0;
  left: 0;
  z-index: -1;
}

.search-filter {
  position: relative;
}

.debug {
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  background: rgba(4, 29, 5, 0.52);
  padding: 1em;
  bottom: 15px;
  right: 15px;

  font-size: 11px;
  border-radius: 12px;
}
.debug__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5em 0;
}
.debug__key {
  padding: .25em .6em;
  background: #19bb2c;
  border-radius: 8px 0 0 8px;
  border: 1px solid #36c539;
  color: rgb(232, 255, 231);
}
.debug__value {
  padding: .25em .6em;
  background: #efefef;
  border-radius: 0 8px 8px 0;
  border: 1px solid #dddddd;
  margin-left: 2px;
}
.debug__title {
  color: #dddddd;
  font-weight: 600;
}

</style>
