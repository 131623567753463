<script>
import Paginate from 'vuejs-paginate';
import {VueContext} from 'vue-context';
import NumberFormatter from './utility/number-formatter';
import BlockPagination from './utility/block-pagination';
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
    name: 'customers',
    data: function () {
        return {
          filter: '',

            customersPerPage: 50,
            customersCurrentPage: 1,
            customersFiltered: 0,

            fields: [
                {
                    label: 'Name',
                    key: 'customer_name',
                    sortable: true,
                    tdClass: 'clickable text-left font-weight-bold td-text',
                    thClass: 'bg-navyblue text-left th-text'
                },
                {
                    label: 'Email',
                    key: 'email',
                    sortable: true,
                    tdClass: 'text-left clickable font-weight-600 td-text',
                    thClass: 'text-left th-text bg-navyblue'
                },
                {
                    label: 'Address',
                    key: 'address',
                    sortable: true,
                    tdClass: 'text-left font-weight-600 td-text clickable',
                    thClass: 'text-left th-text bg-navyblue'
                },
                {
                    label: 'Suburb',
                    key: 'suburb',
                    sortable: true,
                    tdClass: 'text-left font-weight-600 td-text clickable',
                    thClass: 'text-left th-text bg-navyblue'
                },
                {
                    label: 'State',
                    key: 'state',
                    sortable: true,
                    tdClass: 'text-left font-weight-600 td-text clickable',
                    thClass: 'text-left th-text bg-navyblue'
                },
                {
                    label: 'Phone',
                    key: 'phone',
                    sortable: true,
                    tdClass: 'text-left font-weight-600 td-text clickable',
                    thClass: 'text-left th-text bg-navyblue'
                },
                {
                    label: 'Mobile',
                    key: 'mobile',
                    sortable: true,
                    tdClass: 'text-left font-weight-600 td-text clickable',
                    thClass: 'text-left th-text bg-navyblue'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            getterAllCustomers: 'getAllCustomers',
            'isCustomerUser': 'isCustomerUser',
            isStatusActive: 'isStatusActive',
            getterBusinessName : 'getBusinessName',
          isShowBusinessName: 'isShowBusinessName',
        }),
    },
    methods: {
        filterForTable(row, filter) {
          let formattedFilter = _.trim(_.toLower(filter))
          if (row.abn && row.abn.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.address && row.address.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.country && row.country.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.email && row.email.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.fax && row.fax.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.level_unit_lot && row.level_unit_lot.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.mobile && row.mobile.includes(formattedFilter)) {
            return true;
          } else if (row.phone && row.phone.includes(formattedFilter)) {
            return true;
          } else if (row.postcode && row.postcode.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.state && row.state.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.street_name && row.street_name.toLowerCase().includes(formattedFilter)) {
            return true;
          } else if (row.suburb && row.suburb.toLowerCase().includes(formattedFilter)) {
            return true;
          }
          return false;
        },
        onCustomersFiltered: function (fi) {
            this.customersFiltered = fi.length
            this.customersCurrentPage = 1
        },
        onCustomersRowClick: function (item, index, event) {
            this.$router.push('/customers/view/' + item.customer_id)
        }
    },
    mounted: function () {
        this.$refs.activeTab.isActive = true;
    },
    components: {
        Paginate,
        VueContext,
        NumberFormatter,
        BlockPagination,
    }
}
</script>

<template>
    <div class="customers-page table-view">
        <div class="page-header">
            <h4>View Customers</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Customers</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
            <div v-if="!isCustomerUser && isStatusActive" class="page-header-nav-btn">
                <router-link to="/customers/new" class="btn btn-primary plus-btn-hover"><i class="ti-plus"></i>New Customer
                </router-link>
            </div>
        </div>
        <div class="box-block bg-white cards">
            <tabs>
                <tab name="All" id="all-customers" ref="activeTab" :class-name="'tab-content-table'">
                    <div class="row">
                        <div>
                            <div class="bg-white pagination-block-bottom">
                                <b-table
                                    class="rs-scroll rs-scroll--y"
                                    responsive
                                    hover
                                    :items="getterAllCustomers"
                                    :fields="fields"
                                    :filter-function="filterForTable"
                                    :filter="filter"
                                    :current-page="customersCurrentPage"
                                    :per-page="customersPerPage"
                                    @filtered="onCustomersFiltered"
                                    @row-clicked="onCustomersRowClick"
                                    :sort-by="'customer_name'"
                                >
                                </b-table>

                            </div>

                            <block-pagination
                              :role="'customers'"
                              :count="getterAllCustomers.length"
                              :countFiltered="filter ? customersFiltered : getterAllCustomers.length"
                              :currentPage="customersCurrentPage"
                              @changedCurrentPage="value => customersCurrentPage = value"
                              :perPage="customersPerPage"
                              @changedPerPage="value => customersPerPage = value"
                            >
                            </block-pagination>
                        </div>
                    </div>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search right">
                        <b-form-input v-model="filter" type="text" class="form-control search-input search-position"
                                    placeholder="Search or Filter results"
                                    debounce="100"></b-form-input>
                    </div>
                    <div class="tabs-nav-right-btn" v-if="!isCustomerUser && isStatusActive">
                        <router-link to="/customers/new" class="btn add-customer add-button-table plus-btn-hover"><i class="ti-plus"></i>New Customer
                        </router-link>
                    </div>
                </template>
            </tabs>
        </div>
    </div>
</template>
<style>
.font-weight-600 {
    font-weight: 600;
}

.customers-page .add-customer i {
    font-weight: bolder !important;
}

.customers-page .search-input {
    box-sizing: border-box;
    height: 38px;
    width: 100%;
    border: 1px solid #1C1F39;
    border-radius: 3px;
}

.customers-page .add-customer {
    height: 38px;
    position: relative;
    bottom: 1px;
    width: 135px;
    border-radius: 3px;
    background-color: #5E79FF;
    color: #FFFFFF;
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 7px;

}

.customers-page .td-text {
    color: #1C1F39;
    font-family: "Nunito Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.customers-page .th-text {
    color: #1C1F39;
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

</style>
<style scoped>
.ti-plus {
    margin-right: 10px;
}

.btn-primary.ml-1.pull-right {
    height: 37px;
    display: flex;
    align-items: center;
    padding: auto 10px;
    padding: 0px 13px;
    border-radius: 3px;
}

@media (min-width: 626px) {
    .tabs-nav-right-btn {
        margin-left: 15px !important;
    }
}

@media (max-width: 576px) {
    .search-position {
        margin: 10px 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .header-display-name {
        display: none;
    }
}
</style>
