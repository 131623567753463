<script>
    import {isMobile} from '../../deviceDetect/index';
    import draggable from "vuedraggable";
    import imageGallery from "./image-lightbox/image-gallery";
    import { VueContext } from 'vue-context'
    import _ from 'lodash';
    import Axios from "axios";

    export default {
        name: 'image-selector',
        components: {
            draggable: draggable,
            'image-gallery': imageGallery,
          VueContext,
        },
        props: {
          limit: {
            type: Number,
            default: -1
          },
            isSendToORMWizard: {
              type: Boolean,
              default: false,
            },
          isSendToEIOWizard:{
            type: Boolean,
            default: false,
          },
            images: {
                type: Array,
                default: []
            },
            path: {
                type: String,
                default: '/'
            },
            zeroImagesMessage: {
                type: String,
                default: 'No images available'
            }
        },
        data: function () {
            return {
              updateData: {},
                callComputed: 0,
                selectedImages: [],
                isMobile,
                imageList: {},
                shownGallery: null,
                position: "below_drag_area",
                galleryImages: [],
                lastVerions: {},
                isMoveImages: {'Uncategorised': false, 'Existing_Damage': false, 'Accident_Damage': false, 'Supplementary_Damage': false,},
                uploadImageCategories: ['Uncategorised', 'Existing Damage', 'Accident Damage', 'Supplementary Damage',],
                myClass: "padding-left: 0px",
            };
        },
        computed: {
          computedImages() {
            this.callComputed
            return _.concat(this.formatArray(this.imageList['Uncategorised']), this.formatArray(this.imageList['Existing_Damage']), this.formatArray(this.imageList['Accident_Damage']), this.formatArray(this.imageList['Supplementary_Damage']),)
          },
          isSelectedMoreThanLimit() {
            if (this.limit == -1 || !this.limit) {
              return false
            }
            return this.selectedImages.length > this.limit
          },
            cols: function () {
                let col = 0;
                let colNum = 4;
                let columns = {0: [], 1: [], 2: [], 3: []};

                for (let index = 0; index < this.images.length; index++) {
                    if (col >= colNum) {
                        col = 0;
                    }
                    columns[col].push(index);
                    col++;
                }
                return columns;
            },
            allSelectedCategories() {
              return {
                'Uncategorised': this.allSelectedUncategorised,
                'Existing_Damage': this.allSelectedExistingDamage,
                'Accident_Damage': this.allSelectedAccidentDamage,
                'Supplementary_Damage': this.allSelectedSupplementaryDamage,
              }
            },
            allSelectedUncategorised() {
              let imgs = [];
              _.forEach(this.selectedImages, (id) => {
                let img = _.findIndex(this.imageList['Uncategorised'], (img) => {
                  return img.id == id;
                })
                if (img !== -1) {
                  imgs.push(img)
                }
              });
              if (imgs.length === this.imageList['Uncategorised'].length) {
                return true
              }
              return false
            },
            allSelectedExistingDamage() {
              let imgs = [];
              _.forEach(this.selectedImages, (id) => {
                let img = _.findIndex(this.imageList['Existing_Damage'], (img) => {
                  return img.id == id;
                })
                if (img !== -1) {
                  imgs.push(img)
                }
              });
              if (imgs.length === this.imageList['Existing_Damage'].length) {
                return true
              }
              return false
            },
            allSelectedAccidentDamage() {
              let imgs = [];
              _.forEach(this.selectedImages, (id) => {
                let img = _.findIndex(this.imageList['Accident_Damage'], (img) => {
                  return img.id == id;
                })
                if (img !== -1) {
                  imgs.push(img)
                }
              });
              if (imgs.length === this.imageList['Accident_Damage'].length) {
                return true
              }
              return false
            },
            allSelectedSupplementaryDamage() {
              let imgs = [];
              _.forEach(this.selectedImages, (id) => {
                let img = _.findIndex(this.imageList['Supplementary_Damage'], (img) => {
                  return img.id == id;
                })
                if (img !== -1) {
                  imgs.push(img)
                }
              });
              if (imgs.length === this.imageList['Supplementary_Damage'].length) {
                return true
              }
              return false
            },
            allSelected() {
                return this.selectedImages.length === this.images.length;
            },
            categories: function () {
                return Object.keys(this.imageList);
            },
            // categories: function () {
            //   let categories = []
            //   this.images.forEach(function (img){
            //     if (!categories.includes(img.category)){
            //       categories.push(img.category)
            //     }
            //   })
            //   return categories
            // },

        },
        watch: {
            selectedImages: function () {
                // console.log('image clicked', this.selectedImages)
                this.$emit('onChange', this.selectedImages);
            },
            imageList() {
                // when gallery open, update it
                if (this.shownGallery) { // it is null when gallery not open
                    // select other image to show on gallery
                    const currentIndex = this.shownGallery.imageIndex;
                    const category = this.shownGallery.category;
                    const countImages = this.imageList[category].length;

                    // no image left, close the gallery
                    if (!countImages) {
                        // console.log('close gallery')
                        this.$refs['image-gallery'].onClickClose();
                        return;
                    }

                    if (currentIndex <= countImages - 1) {
                        // console.log('choose next image')
                        this.onClickImage(currentIndex, category);
                    } else if (currentIndex > countImages - 1) {
                        // console.log('choose prev image')
                        this.onClickImage(currentIndex - 1, category);
                    } else {
                        // console.log('last, choose first image')
                        this.onClickImage(0, category);
                    }
                }
            }
        },
        mounted() {
          this.setImageList()
            console.log(this.images, 'images')
            console.log(this.categories, 'categories')
            console.log(this.imageList, 'imageList')
        },
        created() {
          this.debouncedAutoSave = _.debounce(this.autoSave, 500)
        },
        methods: {
          activeModeMoveImages(cat){
            this.$set(this.isMoveImages, cat, true)
          },
          cancelModeMoveImages(cat){
            this.$set(this.isMoveImages, cat, false)
          },
          moveToCategory(fromCat, toCat, ids) {
            let images = _.filter(this.imageList[fromCat], (img) => {
              return _.includes(ids, img.id)
            })

            _.forEach(images, (img) => {
              this.imageList[toCat].push(img)
            })

            this.imageList[fromCat] = _.filter(this.imageList[fromCat], (img) => {
              return !_.includes(ids, img.id)
            })

            let update = {}
            var sort = []
            this.callComputed++

            let imagesCopy = _.cloneDeep(this.computedImages)
            _.forEach(imagesCopy, (im) => {
              if (_.includes(ids, (im.id))) {
                im.category = toCat
              }
            })
            let result = _.concat(_.filter(imagesCopy, (i) => {return _.includes(ids, i.id)}), _.filter(imagesCopy, (i) => {return !_.includes(ids, i.id)}))

            result = _.concat(_.filter(result, (r) => {return r.category == 'Uncategorised'}),
                _.filter(result, (r) => {return r.category == 'Existing Damage'}),
                _.filter(result, (r) => {return r.category == 'Accident Damage'}),
                _.filter(result, (r) => {return r.category == 'Supplementary Damage'}),)

            _.forEach(result, (el, index) => {
              sort.push({ id: el.id, index: index + 1 })
            })

            update.imagesSort = sort
            update.imageCategories = _.map(ids, (i) => {
              return {
                file_id: i,
                category: toCat
              }
            })

            let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
            this.updateData[uid] = update
            this.debouncedAutoSave()
          },
          openContextMenu(event, img, category) {
            if (!this.isMoveImages[category] || !this.getSelectedIds(category).length) {
              return
            }
            this.$refs.menuForImage.open(event, { img: img, category: category })
          },
          getSelectedIds(c) {
            let arr = _.filter(this.imageList[c], (img) => {
              return _.includes(this.selectedImages, img.id)
            })
            if (!_.isEmpty(arr)) {
              return _.map(arr, (img) => {
                return img.id
              })
            }
            return []
          },
          autoSave() {
            let vm = this
            return Axios({
              method: 'post',
              responseType: 'json',
              headers: { 'Autosave': true },
              url: `/fe/estimate/${this.$route.params.estimate_id}`,
              validateStatus: function (status) {
                return status < 500
              },
              data: this.updateData
            }).then(response => {
              if (response.status == 200) {
                if (response?.data?.response && response?.data?.response?.result) {
                  _.forEach(response.data.response.result, function (val, k) {
                    if (vm.updateData && vm.updateData[k]) {
                      delete vm.updateData[k]
                    }
                  })
                }
              }
            })
          },
          formatArray(arr) {
            return _.map(arr, (p) => { return { id: p.id, url: p.src, category: p.category } })
          },
          changeImageByDraggable (event, category) {
            if (!this.$route.params.estimate_id) {
              return
            }
            this.callComputed++
            if (!event || !category) {
              return
            }
            if (event.removed) {
              return
            }
            let element = null
            let update = {}
            if (event.added && event.added.element) {
              element = event.added.element
              update.imageCategories = [{
                category: category,
                file_id: element.id
              }]
            }

            let sort = []

            _.forEach(this.computedImages, (el, index) => {
              sort.push({ id: el.id, index: index + 1 })
            })

            update.imagesSort = sort

            let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
            this.updateData[uid] = update
            this.debouncedAutoSave()
          },
          setImageList () {
            let _uncategoriesArr = [],
                _dataObj = {};
            Array.from(this.uploadImageCategories).forEach(el => {
              _dataObj[this.replaceCharAtCategories(el)] = [];
            });
            [].slice.call(Array.from(this.images)).forEach((el, ind) => {
              if (!el.hasOwnProperty('category') || !el.category || this.replaceCharAtCategories(el.category) === 'Uncategorised') {
                _uncategoriesArr.push(el);
                _dataObj.Uncategorised = _uncategoriesArr;
              } else {
                let _categReplace = this.replaceCharAtCategories(el.category);
                _dataObj[_categReplace].push(el);
              }
            });
            this.imageList = _dataObj
          },
            computedStyle(category) {
                if (category === "Uncategorised") {
                    return "padding-left: 0px";
                }
            },
            replaceCharAtCategories: function (elem) {
                return (elem.split(' ').length > 1) ?
                    elem.split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ').replace(/ /g, '_') : elem;
                //elem.split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0).toLowerCase())).join(' ').replace(/ /g, '_') : elem.toLowerCase()
            },
            selectAllItems: function (data) {
                if (data.target.checked) {
                    this.selectedImages = [];
                    this.images.forEach((item) => {
                        if (this.isSendToORMWizard) {
                          if (item.isCanSend) {
                            this.selectedImages.push(item.id);
                          }
                        } else {
                          this.selectedImages.push(item.id);
                        }
                    });
                } else {
                    this.selectedImages = [];
                }
            },

            selectCategoryItems: function (category, event) {
              if (event.target.checked) {
                _.forEach(this.imageList[category], (img) => {
                  this.selectedImages.push(img.id);
                })
              } else {
                _.forEach(this.imageList[category], (img) => {
                  let index = _.findIndex(this.selectedImages, selImgId => {
                    return selImgId == img.id
                  });
                  if (index !== -1) {
                   this.selectedImages.splice(index, 1);
                  }
                })
              }
            },
            isSelected(id) {
                return this.selectedImages.includes(id);
            },
            selectImage(id) {
              this.$refs.menuForImage.close()
                if (!this.isSelected(id)) {
                    console.log('id', id);
                    this.selectedImages.push(id);
                } else {
                    const index = this.selectedImages.findIndex(_id => _id === id);
                    if (index === -1) return;
                    this.selectedImages.splice(index, 1);
                }
            },
            handleKeydown(e, id, index) {
                switch (e.keyCode) {
                    case 32: // space
                    case 13: // enter
                    {
                        e.preventDefault();
                        this.selectImage(id);
                        break;
                    }
                    case 37: // left
                    case 38: // up
                    {
                        e.preventDefault();
                        index = index === 0 ? this.images.length - 1 : index -= 1;
                        this.$refs['image-item'][index].focus();
                        break;
                    }
                    case 39:
                    case 40: {
                        e.preventDefault();
                        index = index === this.images.length ? 0 : index += 1;
                        this.$refs['image-item'][index].focus();
                        break;
                    }
                }
            },
            recoverToUpperCase: function (item) {
                return item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ');
            },
            makeImagePreviewUrl: function (img) {
              return img.previewW220;
                //const versionPart = this.lastVerions[img.id.toString()] ? '?version=' + this.lastVerions[img.id.toString()] : ''; //ToDO need review from old code
                //return (img.previewH240.lastIndexOf('data', 0) === 0 ? img.previewH240 : this.path + img.previewH240) + versionPart;
            },
            onClickRemove: function (data) {
                this.$emit('removeImage', data); // { key: string, id: string }
            },
            onClickImage: function (index, category) {
                const images = category ? this.imageList[category] : this.imageList;
                const imagesData = images.map(p => {
                    return {id: p.id, url: this.makeImageUrl(p)};
                });

                // reset current
                this.onCloseGallery();

                // set after finish reset
                this.$nextTick(() => {
                    this.shownGallery = {
                        category: category,
                        imageIndex: index,
                        images: imagesData
                    };
                });
            },
            makeImageUrl: function (img) {
                return img.src.lastIndexOf('data', 0) === 0 ? img.src : this.path + img.src;
            },
            onCloseGallery: function () {
                this.shownGallery = null;
            },
            onImageSaved: function (id) {
                this.lastVerions[id.toString()] = (new Date()).getTime().toString();
            }
        }
    };
</script>

<template>
    <div class="image-selector grid">
        <div class="image-selector-header">
            <label class="form-check-inline checkbox image-selector__checkbox">
                <input class="form-check-input"
                       ref="image-selectall"
                       type="checkbox"
                       :checked="allSelected"
                       @change="selectAllItems">
                <span class="icon" style="padding-left: 2px;"><i class='bx bx-check'></i></span><span class="text">Select All Images</span>
            </label>
        </div>

        <div class="below_drag_area">
            <image-gallery v-if="shownGallery"
                           ref="image-gallery"
                           :use-editor="false"
                           :images="shownGallery.images"
                           :category="shownGallery.category"
                           :current-index="shownGallery.imageIndex"
                           :showRemove='false'
                           @removeImage="onClickRemove"
                           @close-gallery="onCloseGallery"
                           @change="index => shownGallery.imageIndex = index"
                           @saved-image="onImageSaved">
            </image-gallery>


            <template>
                <div :style="computedStyle(item)" v-for="item,i in categories" :key="'cat'+i" class="each_row_images img-grid"
                     :class="{'' : position === 'below_drag_area', is_empty_block: imageList[item].length === 0, }">
                    <div class="categories_title">
                        <label class="form-check-inline checkbox" style="top: -3px; padding-left: 15px" v-if="imageList[item].length !== 0">
                            <input class="form-check-input"
                                ref="image-selectall"
                                type="checkbox"
                                @change="selectCategoryItems(item, $event)"
                                :checked="allSelectedCategories[item]">
                            <span class="icon mr-0"><i class='bx bx-check'></i></span>
                        </label>
                        <span class="text-title-categories"><span v-if="imageList[item].length !== 0">Select All</span> {{recoverToUpperCase(item)}}</span>
                        <span v-if="isSelectedMoreThanLimit && item ==  'Uncategorised'" class="images-limit-text">Audabridge only accepts a maximum of 20 images</span>

                      <template v-if="imageList[item].length > 0">
                        <button v-if="!isMoveImages[item]"
                                class="categories_title-btn-move-image"
                                @click="activeModeMoveImages(item)"
                                style="float: right; margin-right: 10px">
                          Move Images
                        </button>
                        <button v-if="isMoveImages[item]"
                                class="categories_title-btn-move-image"
                                @click="cancelModeMoveImages(item)"
                                style="float: right; margin-right: 10px">
                          Cancel Move Images
                        </button>
                      </template>

                    </div>
                    <div class="draggable-images rs-scroll" :class="{'no_images_drag_area': imageList[item].length === 0, 'min-images_drag_area': imageList[item].length > 0 && imageList[item].length < 10}">

                        <span v-if="imageList[item].length === 0" :class="imageList[item].length !== 0 ? 'hidden-area-empty-images' : 'area-empty-images'">Empty</span>

                      <draggable
                          draggable=".item_div_drag"
                          :disabled="isMobile"
                          :options="{
                            animation:200,
                            group:'imgList',
                            delay: isMobile ? 400 : false,
                          }"
                          :list="imageList[item]"
                          @change="changeImageByDraggable($event, item)"
                          class="draggable-wrapper"
                          style="padding-top: 2px"
                      >
                        <div :key="img.id" :data-itemArray="[img.id, item]" v-for="(img, ind) in imageList[item]" class="item_div_drag col-4">
                          <div v-if="img.src" class="box box-block mb-1">

                            <div class="image-selector__item" :class="[{'selected': isSelected(img.id)}, `item-${img.id}`]" @keydown="handleKeydown($event, image.id, index)">
                              <div class="row icon-action-block">
                                <button @click="onClickImage(ind, item)"
                                        class="action-button" type="button"
                                        v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomleft', html: true, title:'Enlarge image', placement:'bottomleft'}">
                                  <svg class="enlarge-icon" fill="currentColor" width="20px" height="20px" viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                       xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <!-- Generator: sketchtool 62 (101010) - https://sketch.com -->
                                    <title>0D8EA4A3-1F9F-404B-8374-E502A91B81C9</title>
                                    <desc>Created with sketchtool.</desc>
                                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <g id="Dashboard-Redesign-(Detail-Cards)Images" transform="translate(-462.000000, -509.000000)" fill="currentColor" fill-rule="nonzero">
                                        <g id="Group-3" transform="translate(265.000000, 427.000000)">
                                          <g id="Uncategorised">
                                            <g id="Group-6" transform="translate(0.000000, 63.000000)">
                                              <g id="Group-5" transform="translate(184.000000, 5.000000)">
                                                <g id="eye" transform="translate(13.000000, 14.000000)">
                                                  <path d="M18,6 C18,4.19 14.24,2.015 9.993,1.99992347 C5.775,1.985 2,4.178 2,6 C2,7.825 5.754,10.006 9.997,10.0000124 C14.252,9.994 18,7.82 18,6 Z M10,12.0000136 C4.958,12.007 0,9.314 0,6 C0,2.686 4.984,-0.017 10,-7.97519788e-05 C15.016,0.017 20,2.686 20,6 C20,9.314 15.042,11.993 10,12.0000136 Z M10,10 C7.790861,10 6,8.209139 6,6 C6,3.790861 7.790861,2 10,2 C12.209139,2 14,3.790861 14,6 C14,8.209139 12.209139,10 10,10 Z M10,8 C11.1045695,8 12,7.1045695 12,6 C12,4.8954305 11.1045695,4 10,4 C8.8954305,4 8,4.8954305 8,6 C8,7.1045695 8.8954305,8 10,8 Z"
                                                        id="Shape"></path>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </button>

                              </div>
                              <div class="image-selector__overlay"></div>

                              <label v-if="isSendToORMWizard" class="image-selector__checkbox form-check-inline checkbox" :for="img.id"
                                     v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomright', html: true, title:'This image exceeds the maximum file size that can be sent to ORM', placement:'bottomleft'}">
                                <input class="form-check-input danger-checkbox-size-image"
                                       tabindex="-1"
                                       type="checkbox"
                                       :id="img.id"
                                       v-model="selectedImages"
                                       :value="img.id"
                                       :data-id="img.id"
                                       :disabled="!img.isCanSend">
                                <span v-if="!img.isCanSend" class="icon icon-danger-size-image"><i class='bx bx-check'></i></span>
                                <span v-else class="icon"><i class='bx bx-check'></i></span>
                              </label>

                              <label v-else class="image-selector__checkbox form-check-inline checkbox" :for="img.id">
                                <input class="form-check-input"
                                       tabindex="-1"
                                       type="checkbox"
                                       :id="img.id"
                                       v-model="selectedImages"
                                       :value="img.id"
                                       :data-id="img.id">
                                <span  class="icon"><i class='bx bx-check'></i></span>
                              </label>

                              <a class="hand-pointer img-grid__link" @click="onClickImage(ind, item)">
                                <img @click.stop="selectImage(img.id)" @contextmenu.prevent="openContextMenu($event, img, item)" :src="makeImagePreviewUrl(img)" class="wrap img-fluid w-100 image-selector__img img-responsive">
                              </a>
                            </div>
                          </div>
                        </div>
                      </draggable>
                    </div>
                </div>
            </template>
        </div>


        <!--        <div class="image-selector__list">-->
        <!--            <div v-for="(image, index) in images"-->
        <!--                 class="image-selector__item"-->
        <!--                 :class="[{'selected': isSelected(image.id)}, `item-${image.id}`]"-->
        <!--                 :key="index"-->
        <!--                 ref="image-item"-->
        <!--                 tabindex="0"-->
        <!--                 @keydown="handleKeydown($event, image.id, index)">-->
        <!--                <div class="image-selector__overlay"></div>-->
        <!--                <label class="image-selector__checkbox form-check-inline checkbox" :for="index">-->
        <!--                    <input class="form-check-input"-->
        <!--                           tabindex="-1"-->
        <!--                           type="checkbox"-->
        <!--                           :id="index"-->
        <!--                           v-model="selectedImages"-->
        <!--                           :value="image.id"-->
        <!--                           data-id="image.id">-->
        <!--                    <span class="icon"><i class='bx bx-check'></i></span>-->
        <!--                </label>-->
        <!--                <img :src="makeImagePreviewUrl(image)"-->
        <!--                     class="image-selector__img img-responsive w-100"-->
        <!--                     @click.stop="selectImage(image.id)">-->
        <!--            </div>-->
        <!--        </div>-->

        <p v-if="images.length === 0" class="text-center">{{zeroImagesMessage}}</p>

      <vue-context ref="menuForImage" v-slot="{ data }">
        <ul>
          <li v-if="data && data.category && getSelectedIds(data.category).length" class="hasSubContext">
            Move Image/s
            <i class="icon-right bx bx-chevron-right"></i>
            <ul type="none" class="context-level subContext" style="width: 164px; left: 139px; top: -1px !important;">
              <li v-for="category in categories" v-if="category !== data.category" @click="moveToCategory(data.category, category, getSelectedIds(data.category))">{{ recoverToUpperCase(category) }}</li>
            </ul>
          </li>
        </ul>
      </vue-context>
    </div>
</template>

<style scoped>
  .categories_title-btn-move-image{
    font-weight: 700;
    background-color: white;
    padding: 2px 8px;
    position: relative;
    bottom: 2px;
    border-radius: 3px;
    border: 1px solid rgba(28, 31, 57, 0.25);
  }
    .V3 label.checkbox > span.text {
        width: 145px;
    }
</style>
<style>
    /*.image-selector input[type="checkbox"] {
      display: none;
    }*/

    .V3 .image-selector .image-selector-header {
        height: 48px;
        width: 100%;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.12);
        margin-bottom: 15px;
    }

    .V3 .image-selector .image-selector-header label.checkbox {
        padding-top: 12px;
        margin-left: 15px;
    }

    .image-selector .image-selector-col {
        position: relative;
    }

    .image-selector .image-selector-col label {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        background: #FFFFFF;
        border-radius: 3px;
    }

    .V3 .image-selector .image-selector-col label.checkbox > span.icon {
        margin-right: 0px;
        border: none;
    }

    .image-selector .box-block {
        padding: 0.25rem;
    }

    /*
    .image-selector label:before {
        background-color: #fff;
        color: #fff;
        content: " ";
        display: block;
        border-radius: 50%;
        border: 1px solid #55aa55;
        position: absolute;
        top: -17px;
        left: -17px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 23px;
        transition-duration: .4s;
        transform: scale(0);
        font-weight: bold;
        font-size: 25px;
    }
    */
    .image-selector .image-selector-col label img {
        transition-duration: 0.2s;
        transform-origin: 50% 50%;
        background-color: #FFFFFF;
        width: 100%;
    }

    /*
    .image-selector :checked + label:before {
      content: "✓";
      background-color: #559955;
      transform: scale(1);
    }*/

    .image-selector .image-selector-col :checked + label img {
        /*  transform: scale(0.9); */
        /* box-shadow: 0 0 5px #333333;*/
        /*z-index: -1;*/
    }
</style>

<style scoped>
    .grid .image-selector__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        grid-gap: 15px;
        margin-bottom: 100px;
    }

    .grid .image-selector__item {
        position: relative;
        border-radius: 6px;
        /*height: 169px;*/
        overflow: hidden;
        border: 3px solid transparent;
    }

    .grid .image-selector__item:hover {
        cursor: pointer;
    }

    .grid .image-selector__item.selected {
        border: 3px solid #5E79FF;
    }

    .grid .image-selector__item .image-selector__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 5px;
        background-color: transparent;
        pointer-events: none;
    }

    .grid .image-selector__img {
        border-radius: 3px;
        object-fit: cover;
        object-position: center;
    }

    .grid .selected .image-selector__img {
        border-radius: 3px;
    }

    .grid .image-selector__checkbox {
        position: absolute;
        top: 15.5px;
        /*right: 15.5px;*/
        left: 15.5px;
        margin: 0
    }

    .grid .image-selector__checkbox > span.icon {
        background-color: #F8F8F9;
        margin-right: 15px;
    }

    .grid .image-selector__checkbox > span.icon i.bx {
        font-size: 20px;
    }

    .grid .selected .image-selector__checkbox > span.icon {
        border: 2px solid #FFFFFF;
    }

    @media screen and (max-width: 640px) {
        .grid .image-selector__list {
            grid-template-columns: repeat(2, 1fr);
        }

        .grid .image-selector__item {
            height: 100px;
        }

        .grid .image-selector__checkbox {
            top: 10px;
            right: 10px;
        }
    }
</style>
<style scoped>
    .hand-pointer {
        display: block;
        cursor: pointer;

    }


    .V3 .below_drag_area {
        display: flex;
        flex-flow: row nowrap;

        width: 100%;
        /* height: 70vh; */
        white-space: nowrap;
        overflow: scroll;

        scrollbar-width: thin;
        scrollbar-color: #B7B8C0; /* #5e79ff; */
    }

    .V3 .below_drag_area::-webkit-scrollbar {
        width: unset;
        height: 7px;
    }

    .V3 .below_drag_area::-webkit-scrollbar-thumb {
        background-color: #B7B8C0; /* #5e79ff; */
    }

    .V3 .below_drag_area .box {
        text-align: center;
    }

    .V3 .below_drag_area .box:hover .icon-action-block {
        opacity: 1;
    }

    .V3 .image-pin-area .icon-action-block {
        display: none;
    }

    .V3 .below_drag_area .box img {
        width: 100%;
        border-radius: 3px;
        height: 169px;
        object-fit: cover;
    }

    .V3 .below_drag_area .img-box {
        position: relative;
        width: 100%;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a {
        display: inline-block;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .V3 .below_drag_area .each_row_images > div .item_div_drag {
        width: 100%;
    }

    @media screen and (max-width: 992px) and (min-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            /* width: 33.3%; */
        }
    }

    @media screen and (max-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            width: 100%;
        }
    }

    .V3 .below_drag_area .each_row_images {
        flex: 1 0 728px;

        margin: 15px 0;
        padding: 0 15px;
        border-right: 1px solid lightgray;
        width: 236px;
        display: inline-block;
        text-align: center;
    }

    .V3 .below_drag_area .each_row_images.is_empty_block {
        flex: 1 0 250px;
    }

    .V3 .each_row_images:last-child {
        border-right: none;
    }

    .V3 .below_drag_area .categories_title {
        height: 3rem;
        text-align: start;
        padding-top: 10px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.12);
    }

    .V3 .below_drag_area .area-empty-images {
        opacity: 0.25;
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin: auto;
    }

    .V3 .below_drag_area .hidden-area-empty-images {
        display: none;
    }

    .V3 .below_drag_area .no_images_drag_area {
        height: 47vh !important;
        margin: 1rem 0;
        text-align: center;
        /*padding-top: 82px;*/
    }

    .V3 .below_drag_area .no_images_drag_area .draggable-wrapper{
      height: 95%;
    }

    .V3 .below_drag_area .no_images_drag_area .draggable-wrapper .col-4 {
      flex: 1 !important;
      max-width:100% !important;
      width: 100% !important;
    }

    .V3 .below_drag_area .min-images_drag_area {
        height: 47vh !important;
        margin: 1rem 0;
        text-align: center;
        padding-top: 0;
    }

    .V3 .below_drag_area .draggable-images {
        width: 100%;
        height: 61.8vh;
        overflow: scroll;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .V3 .draggable-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      height: 100%;
    }


    .V3 .below_drag_area .draggable-images .col-4 {
        flex: 0 0 33.333333%;
        max-width: 32%;
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-right: 4px;
        margin-left: 4px;
    }

    .V3 .below_drag_area .draggable-images .col-4 .box-block {
        margin: 0 0 10px 0;
    }

    .V3 .below_drag_area .text-title-categories {
        margin: 1rem;
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        text-transform: unset;
        color: #1C1F39;
        opacity: 1;
    }

    .V3 .text-title-categories {
        text-transform: uppercase;
        opacity: 0.4;
    }

    .V3 .below_drag_area .icon-action-block-width-45 {
        width: 45px !important;
    }

    .V3 .below_drag_area .icon-action-block {
        position: absolute;
        display: block;
        opacity: 0;
        transition: opacity 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
        /*height: 40px;*/
        /*width: 90px;*/
        top: 5px;
        right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        overflow: hidden;
        background-color: #F8F8F8; /* rgba(94, 121, 255, 1); */
    }

    .V3 .below_drag_area .icon-action-block i {
        font-size: 20px;
        font-weight: bold;
    }

    .V3 .below_drag_area .action-button {
        height: 40px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
    }

    .V3 .below_drag_area .action-button:first-child {
        /* border-radius: 3px 0 0 3px; */
    }

    .V3 .below_drag_area .action-button:last-child {
        /* border-radius: 0 3px 3px 0; */
    }

    .V3 .below_drag_area .action-button:hover {
        cursor: pointer;
    }

    .V3 .below_drag_area .action-button:hover .enlarge-icon {
        color: #5E79FF;
    }

    .V3 .below_drag_area .action-button:first-child {
        border-right: 1px solid rgba(27, 30, 56, 0.25);
    }

    .ps-tooltip--bottomright {
        left: 80px !important;
    }

    .grid .image-selector__checkbox > span.icon-danger-size-image::after {
        content: "!";
        font-size: 130%;
        color: red;
    }

    .image-selector-header .image-selector__checkbox {
        top: 0;
        left: 0;
    }
    .V3 .image-selector label.checkbox > span.icon {
        background-color: #F8F8F9;
    }
</style>
<style>
    .image-pin-area .categories_title {
        color: #FFFFFF;
        margin-bottom: 8px;
    }

    /* img-grid           */
    /* some parts from cl */
    .img-grid__link {
        cursor: pointer;
        box-sizing: border-box;
    }

    .V3 .image-selector-header .image-selector__checkbox > span.icon-danger-size-image {
        content: "!";
        color: red;
        padding-left: 10px;
    }
</style>
